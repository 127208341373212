.tags {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
}

.tags__item {
	margin-right: 0.25rem;
	margin-left: 0.25rem;
}

.tags__item:first-child {
	margin-left: 0;
}

.tags__item:last-child {
	margin-left: 0;
}

.tags__label {
	background-color: var(--color-background-alternate);
	color: var(--color-black);
	display: inline-block;
	font-size: 0.6875rem;
	line-height: 1;
	padding: 0.4375rem 0.375rem 0.375rem;
}

.theme-site-alternate .tags__label {
	background-color: var(--color-grey-light);
}

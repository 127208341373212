/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.search-form {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding: var(--space-16);
	justify-content: space-between;
	min-height: 37.5vw;
}

@media (--mq-v2-from-small) {
	.search-form {
		min-height: 25vw;
	}
}

@media (--mq-v2-from-large) {
	.search-form {
		padding: var(--space-28);
		min-height: 12.5vw;
	}
}

@media (--mq-v2-from-xlarge) {
	.search-form {
		padding: var(--space-36);
	}
}

.search-form__input {
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	margin: 0 0 -1.25rem; /* old safari does not like it when you use height: 0 on inputs */
	outline: 0;
	overflow: hidden;
	padding: 0;
	width: 100%;
}

@media (min-width: 1240px) {
	.search-form__input {
		margin-bottom: -1.6875rem; /* old safari does not like it when you use height: 0 on inputs */
	}
}

.search-form[focus-within] .search-form__input {
	margin-bottom: 1rem;
	overflow: auto;
}

.search-form:focus-within .search-form__input {
	margin-bottom: 1rem;
	overflow: auto;
}

.search-form__label {
	cursor: pointer;
	flex-basis: 100%;
	flex-grow: 1;
	flex-shrink: 0;
	width: 100%;
}

.search-form[focus-within] .search-form__label {
	display: none;
}

.search-form:focus-within .search-form__label {
	display: none;
}

.search-form__content {
	overflow: hidden;
	width: 100%;
	height: 0;
}

.search-form[focus-within] .search-form__content {
	overflow: visible;
	height: auto;
}

.search-form:focus-within .search-form__content {
	overflow: visible;
	height: auto;
}

/* #region Title */
:root {
	--font-klaviyo-newsletter-subscribe-popup--title: var(--font-weight-medium) var(--font-size-title-5) / var(--line-height-tight) var(--font-sans);
}

/* mq-v2-from-medium */
@media (--mq-v2-from-medium) {
	:root {
		--font-klaviyo-newsletter-subscribe-popup--title: var(--font-weight-medium) var(--font-size-title-4) / var(--line-height-tight) var(--font-sans);
	}
}
/* #endregion Title */

/* #region Paragraph */
:root {
	--font-klaviyo-newsletter-subscribe-popup--paragraph: var(--font-weight-regular) var(--font-size-default) / var(--line-height-tight) var(--font-sans);
}
/* #endregion Paragraph */

/* #region Form Input */
:root {
	--font-klaviyo-newsletter-subscribe-popup--form-input: var(--font-weight-regular) var(--font-size-default) / var(--line-height-tight) var(--font-sans);
}
/* #endregion Form Input */

/* #region Form Label */
:root {
	--font-klaviyo-newsletter-subscribe-popup--form-label: var(--font-weight-regular) var(--font-size-small) / var(--line-height-roomy) var(--font-sans);
}
/* #endregion Form Label */

.l-cart {
	display: flex;
	align-items: stretch;
}

@media (max-width: 767px) {
	.l-cart {
		flex-wrap: wrap;
	}
}

@media (min-width: 768px) {
	.l-cart {
		min-height: calc(100vh - var(--site-header-height) - var(--site-footer-height));
	}
}

@media (min-width: 1024px) {
	.l-cart {
		min-height: calc(100vh - var(--site-header-height-from-medium) - var(--site-footer-height));
	}
}

/* Column. */
.l-cart__column {
	box-sizing: border-box;
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
}

@media (min-width: 768px) {
	.l-cart__column {
		flex-basis: 33.3333%;
		min-height: calc(100vh - var(--site-header-height) - var(--site-footer-height)); /* Needed for IE11 full height cols */
	}
}

@media (min-width: 1024px) {
	.l-cart__column {
		min-height: calc(100vh - var(--site-header-height-from-medium) - var(--site-footer-height)); /* Needed for IE11 full height cols */
	}
}

.l-cart__column--padding {
	padding: var(--space-16);
}

@media (min-width: 1024px) {
	.l-cart__column--padding {
		padding: var(--space-28);
	}
}

@media (min-width: 1380px) {
	.l-cart__column--padding {
		padding: var(--space-36);
	}
}

@media (max-width: 767px) {
	.l-cart__column:not(:last-child) {
		border-bottom: 1px solid var(--color-grey);
	}
}

@media (min-width: 768px) {
	.l-cart__column:not(:last-child) {
		border-right: 1px solid var(--color-grey);
	}
}

/* Content. */
.l-cart__content {
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
}

@media (min-width: 768px) {
	.l-cart__content {
		border-right: 1px solid var(--color-grey);
		flex-basis: 50%;
	}
}

@media (min-width: 1024px) {
	.l-cart__content {
		flex-basis: 66.6666%;
	}
}

/* Item. */
.l-cart__item {
	padding: var(--space-16);
}

@media (min-width: 1024px) {
	.l-cart__item {
		padding: var(--space-28);
	}
}

@media (min-width: 1380px) {
	.l-cart__item {
		padding: var(--space-36);
	}
}

.l-cart__item:not(:only-child):not(:last-child) {
	border-bottom: 1px solid var(--color-grey);
}

@media (max-width: 767px) {
	.l-cart__item.l-cart__item--call-to-action {
		border-bottom: 1px solid var(--color-grey);
	}
}

/* Summary. */
.l-cart__summary {
	display: flex;
	flex-basis: 100%;
	flex-grow: 1;
	flex-shrink: 1;
	flex-direction: column;
	padding: 0;
}

.l-cart__summary__title {
	margin-bottom: 2.25rem;
}

@media (min-width: 768px) {
	.l-cart__summary__total {
		font-size: 1.125rem;
		line-height: 1.444444;
	}
}

@media (min-width: 1380px) {
	.l-cart__summary__total {
		font-size: 1.5rem;
		line-height: 1.333334;
	}
}

.l-cart__summary__row {
	box-sizing: border-box;
}

.l-cart__summary__row:not(.l-cart__summary__row--accordion) {
	padding: var(--space-16);
}

@media (min-width: 1024px) {
	.l-cart__summary__row:not(.l-cart__summary__row--accordion) {
		padding: var(--space-28);
	}
}

@media (min-width: 1380px) {
	.l-cart__summary__row:not(.l-cart__summary__row--accordion) {
		padding: var(--space-36);
	}
}

.l-cart__summary__row--actions {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

@media (min-width: 768px) {
	.l-cart__summary__row:not(:last-child) {
		border-bottom: 1px solid var(--color-grey);
	}

	.l-cart__summary__row--actions {
		justify-content: flex-end;
	}
}

@media (max-width: 767px) {
	.l-cart__summary__row--actions {
		padding-top: 0 !important;
		padding-bottom: var(--space-36) !important; /* Make sure buttons are clickable on iPhones. */
	}

	.l-cart__summary__row--actions button {
		width: 100%;
	}
}

@media (min-width: 768px) {
	.l-cart__summary__row--actions > a:not(:only-child):not(:first-child) {
		margin-left: 1rem;
	}
}

@media (min-width: 1024px) {
	.l-cart__summary__row--actions > a:not(:only-child):not(:first-child) {
		margin-left: 2rem;
	}
}

@media (min-width: 1680px) {
	.l-cart__summary__row--actions > a:not(:only-child):not(:first-child) {
		margin-left: 3.375rem;
	}
}

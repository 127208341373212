/* Uses the Shopify selector class twice to have higher specificity than Shopify styles */
/* @custom-selector :--button-v2 .button-v2, .shopify-payment-button__button:not(.shopify-payment-button__button--branded); */
@custom-selector :--button-v2 .button-v2.button-v2;

:--button-v2 {
	-mrh-resets: button; /* button reset */
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	/* #region custom button look */
	border-radius: var(--border-radius-round);
	padding-inline: 18px;
	padding-block: 0.4375rem;
	transition:
		color 96ms linear,
		background-color 96ms linear;
	/* #endregion custom button look */

	/* #region Typography */
	font-size: var(--font-size-xlarge);
	font-weight: var(--font-weight-medium);
	line-height: var(--line-height-tight);
	/* #endregion Typography */
}

/* #region Button: Default / Solid Black */
:--button-v2 {
	background-color: var(--color-black);
	color: var(--color-white);
}

:--button-v2[disabled] {
	background-color: var(--color-grey-light);
	color: var(--color-alpha-black-23);
	cursor: not-allowed;
}

:--button-v2:not([disabled]):hover {
	background-color: var(--color-black);
	color: var(--color-yellow);
}

:--button-v2:not([disabled]):active {
	text-decoration: underline;
}
/* #endregion Button: Default / Solid Black */

/* #region Button : Solid Grey */
:--button-v2.button-v2--grey {
	background-color: var(--color-grey-medium);
	color: var(--color-black);
}

:--button-v2.button-v2--grey[disabled] {
	background-color: var(--color-grey-light);
	color: var(--color-alpha-black-23);
	cursor: not-allowed;
}

:--button-v2.button-v2--grey:not([disabled]):hover {
	background-color: var(--color-black);
	color: var(--color-white);
}

:--button-v2.button-v2--grey:not([disabled]):active {
	text-decoration: underline;
}
/* #endregion Button : Solid Grey */

/* #region Button : Outline Black */
:--button-v2.button-v2--outlined {
	background-color: transparent;
	border: 2px solid var(--color-black);
	padding-inline: 16px;
	padding-block: 0.3125rem;
	transition:
		background-color 96ms linear,
		border-color 96ms linear,
		color 96ms linear;
}

:--button-v2.button-v2--outlined {
	border-color: var(--color-black);
	color: var(--color-black);
}

:--button-v2.button-v2--outlined[disabled] {
	background-color: transparent;
	border-color: var(--color-alpha-black-23);
	color: var(--color-alpha-black-23);
	cursor: not-allowed;
}

:--button-v2.button-v2--outlined:not([disabled]):hover {
	background-color: var(--color-yellow);
	color: var(--color-black);
}

:--button-v2.button-v2--outlined:not([disabled]):active {
	background-color: var(--color-yellow);
	text-decoration: underline;
}
/* #endregion Button : Outline Black */

/* #region Button : Outline White */
:--button-v2.button-v2--outlined-white {
	border-color: var(--color-white);
	color: var(--color-white);
}

:--button-v2.button-v2--outlined-white[disabled] {
	background-color: transparent;
	border-color: var(--color-alpha-white-55);
	color: var(--color-alpha-white-55);
	cursor: not-allowed;
}

:--button-v2.button-v2--outlined-white:not([disabled]):hover {
	background-color: var(--color-white);
	color: var(--color-black);
}

:--button-v2.button-v2--outlined-white:not([disabled]):active {
	background-color: var(--color-white);
	color: var(--color-black);
	text-decoration: underline;
}
/* #endregion Button : Outline White */

/* #region Small variation. */
:--button-v2.button-v2--small {
	font-size: var(--font-size-small);
	padding-inline: var(--space-12--px);
	padding-block: var(--space-6);
}

:--button-v2.button-v2--small.button-v2--outlined {
	padding-inline: var(--space-10--px);
	padding-block: var(--space-4);
}
/* #endregion Small variation. */

/* #region Icon */
:--button-v2 .icon {
	fill: currentColor;
	pointer-events: none;
	width: 1.5rem;
	height: 1.5rem;
}

:--button-v2.button-v2--small .icon {
	width: 1.125rem;
	height: 1.125rem;
}

/* Positioning */
.button-v2__icon-left,
.button-v2__icon-right {
	display: flex;
	align-items: center;
}

:--button-v2 .button-v2__icon-right .icon {
	margin-inline-start: var(--space-8--px);
}

:--button-v2 .button-v2__icon-left .icon {
	margin-inline-end: var(--space-8--px);
}
/* #endregion Icon */

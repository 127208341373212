/*
TODO: Deprecated, we will remove this and the associated templates when we
are sure it is not used anymore anywhere throughout the code base
*/
.product-tease {
	background-color: var(--color-white);
	color: var(--color-alpha-black-55);
	display: block;
	overflow: hidden;
	position: relative;
	text-decoration: none;
	width: 100%;
	height: 100%;
	min-height: calc(72.1875vw + 3.125rem);
} /* based on l-grid */

@media (--mq-v2-from-medium) {
	.product-tease {
		min-height: 37.5vw; /* based on l-grid */
	}
}

@media (--mq-v2-from-large) {
	.product-tease {
		min-height: 25vw; /* based on l-grid */
	}
}

a.product-tease:focus,
a.product-tease:hover {
	color: var(--color-black);
}

.product-tease.product-tease--cross-selling-overlay {
	min-height: auto;
}

.product-tease__image {
	display: block;
	position: absolute;
	top: 50%;
	left: var(--space-16);
	transform: translateY(-50%);
	width: calc(100% - (var(--space-16) * 2));
	z-index: 0;
}

@supports (object-fit: contain) {
	.product-tease__image {
		object-fit: contain;
		height: 100%;
	}
}

@media (--mq-v2-from-large) {
	.product-tease__image {
		left: var(--space-28);
		width: calc(100% - (var(--space-28) * 2));
	}
}

@media (--mq-v2-from-xlarge) {
	.product-tease__image {
		left: var(--space-36);
		width: calc(100% - (var(--space-36) * 2));
	}
}

.product-tease--watch:not(.product-tease--gift-box) .product-tease__image,
.product-tease--watches:not(.product-tease--gift-box) .product-tease__image {
	transform: translate(25%, -50%);
}

.product-tease--with-covering-thumbnail .product-tease__image {
	top: 0;
	left: 0;
	transform: none;
	width: 100%;
}

@supports (object-fit: cover) {
	.product-tease--with-covering-thumbnail .product-tease__image {
		object-fit: cover;
		height: 100%;
	}
}

.product-tease__image__hover-image {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
}

@supports (object-fit: cover) {
	.product-tease__image__hover-image {
		object-fit: cover;
		height: 100%;
	}
}

.product-tease__image-picture__hover-image {
	opacity: 0;
	transition: opacity 256ms ease-in-out;
}

.product-tease__content {
	box-sizing: border-box;
	color: currentColor;
	display: flex;
	flex-direction: column;
	padding: var(--space-16);
	position: relative;
	z-index: 3;
}

@media (--mq-v2-from-large) {
	.product-tease__content {
		padding: var(--space-28);
	}
}

@media (--mq-v2-from-xlarge) {
	.product-tease__content {
		padding: var(--space-36);
	}
}

.product-tease--cross-selling-overlay .product-tease__content {
	padding: var(--space-16);
}

@media (--mq-v2-from-xlarge) {
	.product-tease--cross-selling-overlay .product-tease__content {
		padding: var(--space-28);
	}
}

@media (--mq-v2-from-medium) {
	.product-tease--watch .product-tease__content:not(.product-tease--gift-box),
	.product-tease--watches .product-tease__content:not(.product-tease--gift-box) {
		padding-right: 50%;
	}
}

.product-tease__label {
	background-color: rgb(214 214 214 / 0.55);
	display: inline-block;
	font-size: 0.6875rem;
	line-height: 1;
	margin-bottom: var(--space-4);
	padding: var(--space-4) var(--space-5);
}

.product-tease__label [data-product-label-text="Coming soon"] {
	background-color: var(--color-purple);
}

a.product-tease:focus .product-tease__label,
a.product-tease:hover .product-tease__label {
	background-color: rgb(214 214 214 / 1);
}

.product-tease__available-colors {
	color: var(--color-alpha-black-55);
}

.product-tease__colors {
	color: currentColor;
	opacity: 0;
	position: absolute;
	top: var(--space-16);
	right: var(--space-16);
}

@media (--mq-v2-from-large) {
	.product-tease__colors {
		top: var(--space-28);
		right: var(--space-28);
	}
}

@media (--mq-v2-from-xlarge) {
	.product-tease__colors {
		top: var(--space-36);
		right: var(--space-36);
	}
}

.product-tease--watch .product-tease__colors:not(.product-tease--gift-box),
.product-tease--watches .product-tease__colors:not(.product-tease--gift-box) {
	top: auto;
	right: auto;
	bottom: var(--space-16);
	left: var(--space-16);
}

@media (--mq-v2-from-large) {
	.product-tease--watch .product-tease__colors:not(.product-tease--gift-box),
	.product-tease--watches .product-tease__colors:not(.product-tease--gift-box) {
		bottom: var(--space-28);
		left: var(--space-28);
	}
}

@media (--mq-v2-from-xlarge) {
	.product-tease--watch .product-tease__colors:not(.product-tease--gift-box),
	.product-tease--watches .product-tease__colors:not(.product-tease--gift-box) {
		bottom: var(--space-36);
		left: var(--space-36);
	}
}

a.product-tease:active .product-tease__colors,
a.product-tease:focus .product-tease__colors,
a.product-tease:hover .product-tease__colors {
	opacity: 1;
}

/* #region Shots on face (alternate image on hover) */
a.product-tease:hover .product-tease__image-picture__hover-image {
	opacity: 1;
}

/* stylelint-disable-next-line @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.product-tease__will-scroll-out-of-view-marker[active] ~ .product-tease .product-tease__image-picture__hover-image {
	opacity: 1;
}

/* disabled by default */
.product-tease__will-scroll-out-of-view-marker {
	display: none;
}

/* enabled in collection grids */
@media (--mq-v2-up-to-small) {
	.l-grid__item--collection-product .product-tease__will-scroll-out-of-view-marker {
		background-color: red;
		display: block;
		pointer-events: none;
		transform: translateY(calc(100vh - 120px));
		width: 1px;
		height: 1px;
		z-index: calc(var(--z-index-top-layer) - 1);
	}
}

@media (max-width: 413px) {
	/* from the second grid item different placement */
	.l-grid__item--collection-product + .l-grid__item--collection-product .product-tease__will-scroll-out-of-view-marker {
		transform: translateY(calc(75vh));
	}
}

@media (min-width: 414px) {
	/* from the third grid item different placement */
	.l-grid__item--collection-product + .l-grid__item--collection-product + .l-grid__item--collection-product .product-tease__will-scroll-out-of-view-marker {
		transform: translateY(calc(75vh));
	}
}
/* #endregion Shots on face (alternate image on hover) */

/* #region Age group labels */
.product-tease__labels {
	display: flex;
	flex-direction: column;
	margin-top: var(--space-5);
	align-items: flex-start;
}

.product-tease__age-group {
	color: var(--color-black);
	display: inline-block;
	font-size: 0.6875rem;
	font-weight: 700;
	line-height: 1;
	margin-bottom: var(--space-4);
	padding: var(--space-4) var(--space-5);
	text-transform: uppercase;
}

.product-tease__age-group--kiddos {
	background-color: var(--color-orange-yellow);
}

.product-tease__age-group--teens {
	background-color: var(--color-purple);
}
/* #endregion Age group labels */

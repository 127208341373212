.store-pagination {
	display: flex;
	width: 100%;
}

.store-pagination__previous,
.store-pagination__next {
	color: currentColor;
}

.store-pagination__previous {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 1;
}

.store-pagination__next {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 0;
	text-align: right;
}

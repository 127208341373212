/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.l-single-product {
	position: relative;
}

@media (--mq-v2-up-to-large) {
	.l-single-product__sticky-buy {
		position: fixed;
		bottom: -32px;
		transform: translateY(-48px);
		transition: transform 320ms cubic-bezier(0.25, 0, 0.1, 1);
		width: 100%;
		z-index: var(--z-index-over-page);
	}
}

.l-single-product__sticky-buy.l-single-product__sticky-buy--hidden {
	transform: translateY(4px);
}

@media (--mq-v2-from-large) {
	.l-single-product__sticky-buy {
		padding-right: var(--space-28);
		padding-left: var(--space-28);
		bottom: var(--site-header-height-from-medium);
	}
}

@media (--mq-v2-from-xlarge) {
	.l-single-product__sticky-buy {
		padding-right: var(--space-36);
		padding-left: var(--space-36);
	}
}

@media (--mq-v2-from-large) {
	body[has-notice-bar] .l-single-product__sticky-buy {
		bottom: var(--site-header-with-notice-bar-height-from-medium);
	}
}

.l-single-product__sticky-buy__price {
	display: inline-block;
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	margin-right: var(--space-4);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.l-single-product__sticky-buy__add-to-cart,
.l-single-product__sticky-buy__buy-now {
	width: auto;
}

@supports (width: max-content) {
	.l-single-product__sticky-buy__add-to-cart,
	.l-single-product__sticky-buy__buy-now {
		width: max-content;
	}
}

.l-single-product__sticky-buy__buy-now,
.l-single-product__sticky-buy__form {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
}

.l-single-product__sticky-buy__form:not(:last-child) {
	margin-right: var(--space-4);
}

@media (--mq-v2-from-medium) {
	.l-single-product__sticky-buy__form:not(:last-child),
	.l-single-product__sticky-buy__price:not(:last-child) {
		margin-right: var(--space-8);
	}
}

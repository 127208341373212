/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.form {
	padding-top: 0.25rem;
}

.form.form--assistance {
	padding-top: 0;
}

@media (min-width: 1280px) {
	.form.form--assistance {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		max-height: 720px;
	}
}

.form a:not([class]) {
	/* reset inline link to more traditional style */
	margin-right: 0;
	padding-right: 0;
}

.form a:not([class])::after,
.form a:not([class])::before {
	content: none;
	display: none;
}

/**
	 * Used by Shopify's theme-address functionality.
	 *
	 * @link https://github.com/Shopify/theme-scripts/tree/master/packages/theme-addresses
	 */

.form *[data-aria-hidden] {
	display: none;
}

.form input,
.form select:focus,
.form textarea {
	font-size: 16px;
}

.form__fieldset {
	border: 0;
	border-radius: 0;
	margin: 0;
	padding: 0;
}

.form__fieldset:not(:last-child) {
	margin-bottom: 0.625rem;
}

.form__row {
	width: 100%;

	/* end TODO */
}

@media (--mq-v2-from-large) {
	.form__row {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.form__row.form__row--centered {
		justify-content: center;
	}
}

.form__row:not(:last-child) {
	margin-bottom: 0.625rem;
}

.form__row.form__row--extra-padding:not(:last-child) {
	padding-bottom: 1.375rem;
}

.form__row.form__row--extra-padding:not(:first-child) {
	padding-top: 1.375rem;
}

@media (--mq-v2-from-large) {
	.form__row.form__row--indented {
		box-sizing: border-box;
		padding-left: calc(33.3333% - 0.5rem);
	}
}

.form__row.form__row--submit {
	display: block;
	padding-top: 1.5rem;
	text-align: right;
}

.form__row + .form__row.form__row--submit {
	padding-top: 0.875rem;
}

.form--assistance .form__row {
	display: block;
	margin-bottom: 2rem;
}

@media (min-width: 1280px) {
	.form--assistance .form__row {
		width: 50%;
	}
}

@media (--mq-v2-from-xlarge) {
	.form--assistance .form__row {
		margin-bottom: 3rem;
	}
}

/** start TODO
	 * This stuff is done with JS, so we should either do it without JS,
	 * or fully embrace it and not bother with this CSS.
	 */

.form[active-type="person"] .form__row[hide-for-type="person"] {
	display: none;
}

.form[active-type="company"] .form__row[hide-for-type="company"] {
	display: none;
}

.form[active-type="ups"] .form__row[hide-for-type="ups"] {
	display: none;
}

.form:not([active-type="person"]) .form__row[show-for-type="person"] {
	display: none;
}

.form:not([active-type="company"]) .form__row[show-for-type="company"] {
	display: none;
}

.form:not([active-type="ups"]) .form__row[show-for-type="ups"] {
	display: none;
}

.form__subtitle {
	display: inline-block;
	margin: 0;
	padding-top: 1rem;
	padding-bottom: 1.625rem;
	text-align: right;
	width: 100%;
}

/*
This class does not seem to be used anywhere in
the shopify/templates/customers/addresses.liquid template.
Maybe we can remove this later.
*/
.form__card {
	background-color: var(--color-alpha-black-10);
	box-sizing: border-box;
	padding: var(--space-16);
	width: 100%;
}

.form__validation-message {
	color: var(--color-grey-dark);
}

@media (--mq-v2-from-large) {
	.form__validation-message {
		box-sizing: border-box;
		padding-left: calc(33.3333% - 0.5rem);
	}
}

.form__message.form__message--error {
	color: var(--color-alert);
}

.form__message.form__message--success {
	color: var(--color-success);
}

.form__message * {
	color: inherit;
	font-size: inherit;
	line-height: inherit;
}

/* Shopify form errors use ul>li without classes */

.form__message ul:not([class]) li:not([class])::before {
	background-color: currentColor;
}

.form__checkbox-group,
.form__select-group,
.form__radio-group {
	width: 100%;
}

.form__checkbox-group:not(:first-child),
.form__select-group:not(:first-child),
.form__radio-group:not(:first-child) {
	padding-top: 0.625rem;
}

.form__row > .form__checkbox-group,
.form__row > .form__select-group,
.form__row > .form__radio-group {
	padding-top: 0;
}

@media (--mq-v2-from-large) {
	.form--assistance .form__checkbox-group,
	.form--assistance .form__select-group,
	.form--assistance .form__radio-group {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		max-height: 140px;
	}
}

@media (--mq-v2-from-large) {
	.form__select-group {
		display: flex;
		justify-content: space-between;
	}
}

@media (--mq-v2-from-large) {
	.form__select-group .form__select-group__item {
		flex-basis: calc(33.3333% - 0.4375rem);
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.form__checkbox,
.form__radio {
	margin-bottom: 0.3125rem;
	width: 100%;
}

.form__checkbox > input,
.form__radio > input {
	border: none;
	clip: rect(0 0 0 0);
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
	height: 1px;
}

/* :focus-visible with plain :focus fallback */

.form__checkbox > input:focus + label,
.form__radio > input:focus + label {
	background-color: var(--color-yellow);
}

.form__checkbox > input:focus:not(:focus-visible) + label,
.form__radio > input:focus:not(:focus-visible) + label {
	background-color: transparent;
}

.form__checkbox > input:focus-visible + label,
.form__radio > input:focus-visible + label {
	background-color: var(--color-yellow);
}

.form__checkbox > label,
.form__radio > label {
	border-radius: 0;
	box-sizing: border-box;
	color: currentColor;
	display: inline-block;
	font-family: inherit;
	font-size: inherit;
	outline: 0;
	padding: 0 0 0 1.875rem;
	position: relative;
	user-select: none;
}

.form__checkbox > label::before,
.form__radio > label::before {
	background-color: var(--color-background-alternate);
	content: "";
	display: block;
	position: absolute;
	top: 0.1875rem;
	left: 0;
	width: 1rem;
	height: 1rem;
}

.form__checkbox > label::after,
.form__radio > label::after {
	border-bottom: 2px solid var(--color-foreground);
	border-left: 2px solid var(--color-foreground);
	box-sizing: border-box;
	content: "";
	display: none;
	position: absolute;
	top: 0.4375rem;
	left: 0.1875rem;
	transform: rotate(-45deg);
	width: 0.625rem;
	height: 0.375rem;
}

.form__checkbox > label:hover,
.form__radio > label:hover {
	cursor: pointer;
}

.theme-site-alternate .form__checkbox > label::before,
.theme-site-alternate .form__radio > label::before {
	background-color: var(--color-background);
}

.form__checkbox > input[type="radio"] + label::before,
.form__radio > input[type="radio"] + label::before {
	border-radius: 50%;
}

.form__checkbox > input[type="checkbox"] + label::before,
.form__radio > input[type="checkbox"] + label::before {
	border-radius: 0;
}

.form__checkbox > input:checked + label::after,
.form__radio > input:checked + label::after {
	display: block;
}

.form__checkbox.form__checkbox--outline > label::after,
.form__checkbox.form__radio--outline > label::after,
.form__radio.form__checkbox--outline > label::after,
.form__radio.form__radio--outline > label::after {
	content: none;
	display: none;
}

.form__checkbox.form__checkbox--outline > label::before,
.form__checkbox.form__radio--outline > label::before,
.form__radio.form__checkbox--outline > label::before,
.form__radio.form__radio--outline > label::before {
	background-color: transparent;
	border-color: var(--color-foreground);
	border-style: solid;
	border-width: 1px;
	box-sizing: border-box;
	width: 0.75rem;
	height: 0.75rem;
}

.form__checkbox.form__checkbox--outline > input:checked + label::before,
.form__checkbox.form__radio--outline > input:checked + label::before,
.form__radio.form__checkbox--outline > input:checked + label::before,
.form__radio.form__radio--outline > input:checked + label::before {
	background-color: var(--color-foreground);
}

.form__checkbox > input[type="checkbox"].has-validation-error + label::before,
.form__checkbox > input[type="radio"].has-validation-error + label::before,
.form__radio > input[type="checkbox"].has-validation-error + label::before,
.form__radio > input[type="radio"].has-validation-error + label::before {
	border: 1px solid var(--color-alert);
	box-sizing: border-box;
}

@media (--mq-v2-from-large) {
	.form--assistance .form__checkbox,
	.form--assistance .form__radio {
		width: 50%;
	}
}

.form__checkbox__note,
.form__radio__note {
	box-sizing: border-box;
	color: var(--color-grey-dark);
	display: inline-block;
	margin: 0.25rem 0 1rem;
	padding-left: 1.875rem;
	max-width: 400px;
}

.form__input,
.form__select {
	-webkit-appearance: none;
	background-color: var(--color-background-alternate);
	border-color: transparent;
	border-style: solid;
	border-width: 1px;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	display: block;
	line-height: 2.625rem;
	outline: 0;
	padding-right: 2rem;
	padding-left: 1rem;
	width: 100%;
	height: 2.625rem;
}

.form__input[hidden],
.form__select[hidden] {
	display: none;
}

.form__input:active,
.form__input:focus,
.form__select:active,
.form__select:focus {
	border-color: var(--color-grey);
}

.form__input.has-validation-error:not(:focus):not(:active),
.form__select.has-validation-error:not(:focus):not(:active) {
	border-color: var(--color-alert);
}

.theme-site-alternate .form__input,
.theme-site-alternate .form__select {
	background-color: var(--color-background);
}

.form__label {
	hyphens: auto;
	overflow-wrap: break-word;
}

@media (--mq-v2-up-to-large) {
	.form__label {
		display: inline-block;
		margin-bottom: 0.25rem;
	}
}

@media (--mq-v2-from-large) {
	.form__label {
		box-sizing: border-box;
		flex-basis: calc(33.3333% - 0.5rem);
		flex-grow: 0;
		flex-shrink: 0;
		padding-right: 0.5rem;
	}
}

.form--assistance .form__label {
	display: block;
	margin-bottom: 1rem;
	width: 100%;
}

.form__select {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjAgODAiPjxwYXRoIGQ9Ik0xMDIuNiwxMEw2MCw2Ni44TDE3LjQsMTBIMTAyLjYgTTExMi4yLDBINy44QzMuNSwwLDEuMSw0LjksMy42LDguM2w1Mi4yLDY5LjZjMSwxLjQsMi42LDIuMSw0LjEsMi4xczMuMS0wLjcsNC4xLTIuMSBsNTIuMi02OS42QzExOC45LDQuOSwxMTYuNSwwLDExMi4yLDBMMTEyLjIsMHoiLz48L3N2Zz4K");
	background-position: calc(100% - 15px) 50%;
	background-repeat: no-repeat;
	background-size: 10px 15px;
}

.form__select::-ms-expand {
	display: none;
}

@media (--mq-v2-up-to-large) {
	.form__select-group .form__select-group__item:not(:last-child) .form__select {
		margin-bottom: 0.625rem;
	}
}

.form__select__box {
	flex-grow: 1;
	width: 100%;
}

.form__textarea {
	-webkit-appearance: none;
	background-color: var(--color-background-alternate);
	border-color: transparent;
	border-style: solid;
	border-width: 1px;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	display: block;
	font-family: var(--font-sans);
	outline: 0;
	padding: 1rem;
	resize: vertical;
	width: 100%;
}

.form__textarea:active,
.form__textarea:focus {
	border-color: var(--color-grey);
}

.form__textarea.has-validation-error:not(:focus) {
	border-color: var(--color-alert);
}

.theme-site-alternate .form__textarea {
	background-color: var(--color-background);
}

.form__input:-webkit-autofill,
.form__input:-webkit-autofill:focus,
.form__input:-webkit-autofill:hover,
.form__select:-webkit-autofill,
.form__select:-webkit-autofill:focus,
.form__select:-webkit-autofill:hover,
.form__textarea:-webkit-autofill,
.form__textarea:-webkit-autofill:focus,
.form__textarea:-webkit-autofill:hover {
	-webkit-text-fill-color: var(--color-black);
	-webkit-box-shadow: 0 0 0 1000px var(--color-yellow) inset;
	border-color: var(--color-yellow);
}

.form__input:-webkit-autofill::selection,
.form__input:-webkit-autofill:focus::selection,
.form__input:-webkit-autofill:hover::selection,
.form__select:-webkit-autofill::selection,
.form__select:-webkit-autofill:focus::selection,
.form__select:-webkit-autofill:hover::selection,
.form__textarea:-webkit-autofill::selection,
.form__textarea:-webkit-autofill:focus::selection,
.form__textarea:-webkit-autofill:hover::selection {
	background-color: #000000;
	color: #ffffff;
}

.form__input:-webkit-autofill:focus,
.form__select:-webkit-autofill:focus,
.form__textarea:-webkit-autofill:focus {
	border-color: var(--color-grey);
}

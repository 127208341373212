.calendly {
}

.l-showcase__item[data-calendly] {
	background-color: var(--color-white);
}

.calendly__intro {
	margin-bottom: var(--space-20);
}

.calendly__options {
	margin-bottom: var(--space-20);
}

.calendly__radio {
	display: block;
	margin-bottom: var(--space-10);
}

.calendly__radio__input {
	accent-color: var(--color-black);
}

.calendly__options__label {
	font-size: 1.25rem;
	font-weight: 400;
	line-height: 1.3;
	margin-left: var(--space-10);
}

.calendly__text,
.calendly__text p {
	font-size: var(--font-size-title-5);
	font-weight: 500;
	line-height: 1.5;
}

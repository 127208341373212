/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.spotlight-hover {
	display: block;
	overflow: hidden;
	position: relative;
}

/**
 * Prev / Next buttons
 */
.spotlight-hover__prev,
.spotlight-hover__next {
	background-color: inherit;
	border: 0;
	cursor: pointer;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 16.66vw;
	z-index: 7;
}

@media (min-width: 768px) {
	.spotlight-hover__prev,
	.spotlight-hover__next {
		display: none;
	}
}

.spotlight-hover__prev:focus,
.spotlight-hover__prev:active,
.spotlight-hover__next:focus,
.spotlight-hover__next:active {
	outline: 0;
}

.spotlight-hover__prev::before,
.spotlight-hover__next::before {
	border-color: transparent currentColor transparent transparent;
	border-style: solid;
	border-width: 5px 8px;
	color: var(--color-grey);
	content: "";
	font-size: 1.5rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.spotlight-hover__prev {
	left: 0;
}

.spotlight-hover__prev::before {
	transform: translate(calc(-50% - 4px), -50%);
}

.spotlight-hover__next {
	right: 0;
}

.spotlight-hover__next::before {
	transform: translate(calc(-50% + 4px), -50%) rotate(180deg);
}

/**
 * Container
 */
@media (max-width: 767px) {
	.spotlight-hover__container {
		display: block !important;
		margin: 0 auto;
		position: relative;
		width: calc(100vw / 3 * 2);
		height: 100vw;
		z-index: 0;
	}
}

/**
 * Item
 */
.spotlight-hover__item {
	background-color: transparent;
	transition:
		color 80ms linear,
		transform 512ms cubic-bezier(0.39, 0.575, 0.565, 1);
}
/* easeOutSine */
@media (max-width: 767px) {
	.spotlight-hover__item {
		position: absolute !important;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
	}
}

.no-js .spotlight-hover:hover .spotlight-hover__item:not(:hover),
.spotlight-hover__item.is-inactive {
	color: var(--color-grey) !important;
}
/**
	 * Animations mobile
	 */
@media (max-width: 767px) {
	.spotlight-hover[current="0"] .spotlight-hover__item:nth-of-type(1) {
		transform: translate(0, 0);
		transition-duration: 256ms;
		z-index: 20;
	}
}

@media (max-width: 767px) {
	.spotlight-hover[previous="0"] .spotlight-hover__item:nth-of-type(1) {
		z-index: 10;
	}
}

@media (max-width: 767px) {
	.spotlight-hover[current="1"] .spotlight-hover__item:nth-of-type(1) {
		transform: translate(-100%, 0);
	}
}

@media (max-width: 767px) {
	.spotlight-hover[current="2"] .spotlight-hover__item:nth-of-type(1) {
		transform: translate(100%, 0);
	}
}

@media (max-width: 767px) {
	.spotlight-hover[current="1"] .spotlight-hover__item:nth-of-type(2) {
		transform: translate(0, 0);
		transition-duration: 256ms;
		z-index: 20;
	}
}

@media (max-width: 767px) {
	.spotlight-hover[previous="1"] .spotlight-hover__item:nth-of-type(2) {
		z-index: 10;
	}
}

@media (max-width: 767px) {
	.spotlight-hover[current="2"] .spotlight-hover__item:nth-of-type(2) {
		transform: translate(-100%, 0);
	}
}

@media (max-width: 767px) {
	.spotlight-hover[current="0"] .spotlight-hover__item:nth-of-type(2) {
		transform: translate(100%, 0);
	}
}

@media (max-width: 767px) {
	.spotlight-hover[current="2"] .spotlight-hover__item:nth-of-type(3) {
		transform: translate(0, 0);
		transition-duration: 256ms;
		z-index: 20;
	}
}

@media (max-width: 767px) {
	.spotlight-hover[previous="2"] .spotlight-hover__item:nth-of-type(3) {
		z-index: 10;
	}
}

@media (max-width: 767px) {
	.spotlight-hover[current="0"] .spotlight-hover__item:nth-of-type(3) {
		transform: translate(-100%, 0);
	}
}

@media (max-width: 767px) {
	.spotlight-hover[current="1"] .spotlight-hover__item:nth-of-type(3) {
		transform: translate(100%, 0);
	}
}

/**
 * Image
 */
.spotlight-hover__image--cover {
	opacity: 0;
}

@media (min-width: 768px) {
	.spotlight-hover__image--cover {
		transition: opacity 80ms linear;
	}
}

@media (max-width: 767px) {
	.spotlight-hover__image--cover {
		opacity: 1;
	}
}

@media (min-width: 768px) {
	.spotlight-hover__item:hover .spotlight-hover__image--cover {
		opacity: 1;
	}
}
/**
	 * Animations desktop
	 */
@media (min-width: 768px) {
	.spotlight-hover[current="0"] .spotlight-hover__item[idx="0"] .spotlight-hover__image--cover {
		opacity: 1;
	}
}

@media (min-width: 768px) {
	.spotlight-hover[current="1"] .spotlight-hover__item[idx="1"] .spotlight-hover__image--cover {
		opacity: 1;
	}
}

@media (min-width: 768px) {
	.spotlight-hover[current="2"] .spotlight-hover__item[idx="2"] .spotlight-hover__image--cover {
		opacity: 1;
	}
}

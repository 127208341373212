/* TODO: See MISTAKES.md → l-showcase */

/*
 * Each showcase row needs to be exactly 100vw
 * This pushes open the viewport.
 * Adding a container with 100% fixes this.
 */
.l-showcase-container {
	overflow: hidden;
	width: 100%;
}

.l-showcase {
	display: flex;
	overflow: hidden;
	align-items: stretch;
	position: relative;
	width: 100vw;
}

@media (max-width: 767px) {
	.l-showcase {
		flex-direction: column;
		flex-wrap: wrap;
	}
}

@media (min-width: 768px) {
	.l-showcase {
		height: 50vw;
	}
}

.l-showcase--square {
	flex-direction: column;
	flex-wrap: wrap;
	height: 100vw;
}

/* #region Hotspots */
.l-showcase--hotspots {
	height: auto;
}

@media (min-width: 768px) {
	.l-showcase--hotspots .cta {
		padding: var(--space-16);
	}
}

@media (min-width: 1024px) {
	.l-showcase--hotspots .cta {
		padding: var(--space-28);
	}
}

@media (min-width: 1380px) {
	.l-showcase--hotspots .cta {
		padding: var(--space-36);
	}
}

/* #region Hotspots Default */
.l-showcase--hotspots-default .cta {
	position: absolute;
	z-index: 1;
}

@media (max-width: 767px) {
	.l-showcase--hotspots-default .cta {
		padding: var(--space-16);
	}
}
/* #endregion Hotspots Default */

/* #region Hotspots With Background */
.l-showcase--hotspots-with-background {
	box-sizing: border-box;
	padding: var(--space-16) var(--space-16) var(--space-20) var(--space-16);
}

@media (min-width: 768px) {
	.l-showcase--hotspots-with-background {
		box-sizing: border-box;
		padding: 0;
	}
}

@media (max-width: 767px) {
	.l-showcase--hotspots-with-background .cta {
		margin-right: var(--space-10);
	}
}

@media (min-width: 768px) {
	.l-showcase--hotspots-with-background .cta {
		position: absolute;
		z-index: 1;
	}
}
/* #endregion Hotspots With Background */
/* #endregion Hotspots */

.l-showcase--product-shot {
	height: 75vw;
}

@media (min-width: 768px) {
	.l-showcase--height-auto {
		height: auto;
	}
}

/* #region Background. */
.l-showcase__background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.l-showcase__background {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}
/* #endregion */

/* #region Background. */
.l-showcase__item {
	flex-basis: 100vw;
	flex-grow: 0;
	flex-shrink: 0;
	position: relative;
	z-index: 3;
}

@media (min-width: 768px) {
	.l-showcase__item {
		flex-basis: 100vw;
		overflow-x: hidden;
		overflow-y: auto;
	}
}

@media (max-width: 767px) {
	.l-showcase__item--portrait-on-mobile {
		flex-basis: 80vh;
	}
}
/* #endregion */

/* #region Double layout. */
.l-showcase--double-split .l-showcase__item {
	flex-basis: 100vw;
	flex-grow: 0;
	flex-shrink: 0;
}

@media (min-width: 768px) {
	.l-showcase--double-split .l-showcase__item {
		flex-basis: 50vw;
	}
}

.l-showcase--double-small-wide .l-showcase__item,
.l-showcase--double-wide-small .l-showcase__item {
	flex-basis: 100vw;
	flex-grow: 1;
	flex-shrink: 1;
}

@media (min-width: 768px) {
	.l-showcase--double-wide-small .l-showcase__item:nth-child(1) {
		flex-basis: 66.6666vw;
	}
}

@media (min-width: 768px) {
	.l-showcase--double-wide-small .l-showcase__item:nth-child(2) {
		flex-basis: 33.3333vw;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (min-width: 768px) {
	.l-showcase--double-small-wide .l-showcase__item:nth-child(1) {
		flex-basis: 33.3333vw;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (min-width: 768px) {
	.l-showcase--double-small-wide .l-showcase__item:nth-child(2) {
		flex-basis: 66.6666vw;
	}
}
/* #endregion */

/* #region Triple layout. */
@media (min-width: 768px) {
	.l-showcase--triple .l-showcase__item {
		flex-basis: 33.3333vw;
	}
}
/* #endregion */

/* #region Exceptions. */
.l-showcase--square .l-showcase__item,
.l-showcase--product-shot .l-showcase__item {
	flex-basis: 50vw;
	flex-grow: 0;
	flex-shrink: 0;
	width: 50vw;
}

.l-showcase--product-shot .l-showcase__item {
	flex-basis: 100vw;
	flex-grow: 0;
	flex-shrink: 0;
	width: 100vw;
}
/* #endregion */

/* #region Alignment. */
.l-showcase__item--bottom-left,
.l-showcase__item--bl,
.l-showcase__item--bottom-right,
.l-showcase__item--br,
.l-showcase__item--top-right,
.l-showcase__item--tr,
.l-showcase__item--center,
.l-showcase__item--bottom-center {
	display: flex;
}

.l-showcase__item--bottom-right,
.l-showcase__item--br,
.l-showcase__item--top-right,
.l-showcase__item--tr {
	justify-content: flex-end;
	text-align: right;
}

.l-showcase__item--bottom-left,
.l-showcase__item--bl,
.l-showcase__item--bottom-right,
.l-showcase__item--br {
	align-items: flex-end;
}

.l-showcase__item--center {
	justify-content: center;
	align-items: center;
}

.l-showcase__item--bottom-center {
	justify-content: center;
	align-items: flex-end;
}
/* #endregion */

/* #region Item image. */
.l-showcase__item__image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.l-showcase__item__image {
		object-fit: cover;
		height: 100%;
	}
}

.l-showcase--height-auto .l-showcase__item__image {
	position: static;
	top: auto;
	left: auto;
}

@supports (object-fit: cover) {
	.l-showcase--height-auto .l-showcase__item__image {
		object-fit: none;
		height: auto;
	}
}
/* #endregion */

/* #region Item video. */
.l-showcase__item__video {
	display: block;
	position: absolute;
	top: auto;
	left: 50%;
	transform: translateX(-50%);
	width: auto;
	max-width: none;
	height: 100%;
}

@supports (object-fit: cover) {
	.l-showcase__item__video {
		object-fit: cover;
		left: 0;
		transform: none;
		width: 100%;
	}
}
/* #endregion */

/* #region Content. */
.l-showcase__content {
	box-sizing: border-box;
	color: currentColor;
	padding: var(--space-16);
	position: relative;
	text-align: inherit;
	width: 100%;
	z-index: 2;
}

@media (min-width: 1024px) {
	.l-showcase__content {
		padding: var(--space-28);
	}
}

@media (min-width: 1380px) {
	.l-showcase__content {
		padding: var(--space-36);
	}
}

.l-showcase__content ul:not([class]) > li:not([class])::before {
	background-color: currentColor;
}

.l-showcase__content ol:not([class]) > li:not([class])::before {
	color: currentColor;
}

.l-showcase--height-auto .l-showcase__content {
	position: absolute;
	top: 0;
	left: 0;
}

.l-showcase--height-auto .l-showcase__item--bottom-right .l-showcase__content,
.l-showcase--height-auto .l-showcase__item--br .l-showcase__content,
.l-showcase--height-auto .l-showcase__item--top-right .l-showcase__content,
.l-showcase--height-auto .l-showcase__item--tr .l-showcase__content {
	right: 0;
	left: auto;
}

.l-showcase--height-auto .l-showcase__item--bottom-left .l-showcase__content,
.l-showcase--height-auto .l-showcase__item--bl .l-showcase__content,
.l-showcase--height-auto .l-showcase__item--bottom-right .l-showcase__content,
.l-showcase--height-auto .l-showcase__item--br .l-showcase__content,
.l-showcase--height-auto .l-showcase__item--bottom-center .l-showcase__content {
	top: auto;
	bottom: 0;
}

.l-showcase__item--center .l-showcase__content,
.l-showcase__item--bottom-center .l-showcase__content {
	text-align: center;
}
/* #endregion */

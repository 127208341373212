.signature-dots {
	display: inline-block;
	padding-right: 12px;
	position: relative;
}

@media (min-width: 1280px) {
	.signature-dots {
		padding-right: 18px;
	}
}

.signature-dots::after {
	content: "";
	position: absolute;
	top: 0;
	left: calc(100% - 11px);
}

@media (min-width: 1280px) {
	.signature-dots::after {
		left: calc(100% - 17px);
	}
}

.signature-dots.is-hover-active::after,
.is-hover-active .signature-dots::after {
	animation: dots-ease-in infinite forwards 1024ms linear;
}

.signature-dots.is-hover-active.signature-dots--plain::after,
.is-hover-active .signature-dots.signature-dots--plain::after {
	animation-duration: 1920ms;
	animation-name: dots;
}

.signature-dots.is-hover-active.signature-dots--smooth::after,
.is-hover-active .signature-dots.signature-dots--smooth::after {
	animation-duration: 1280ms;
	animation-name: dots-ease-in;
}

.signature-dots.is-hover-active.signature-dots--fast::after,
.is-hover-active .signature-dots.signature-dots--fast::after {
	animation-delay: 280ms;
	animation-duration: 1056ms;
}

.signature-dots.is-hover-active.signature-dots--ultimate::after,
.is-hover-active .signature-dots.signature-dots--ultimate::after {
	animation-duration: 1024ms;
	animation-name: dots-ease-in;
}

@keyframes dots {
	0% {
		content: "";
	}

	25% {
		content: ".";
	}

	50% {
		content: "..";
	}

	75% {
		content: "...";
	}
}

@keyframes dots-ease-in {
	0% {
		content: "";
	}

	8% {
		content: ".";
	}

	18% {
		content: "..";
	}

	36% {
		content: "...";
	}
}

.skip-to-main-content-link {
	background-color: var(--color-yellow);
	box-sizing: border-box;
	color: var(--color-foreground);
	font-size: 1.1875rem;
	padding: 0.5rem;
	position: absolute;
	top: -9999px; /* Can't be invisible */
	left: 0.5rem;
	z-index: 9999999999;
} /* Absolutely has to be highest */

.skip-to-main-content-link:focus {
	top: 0.5rem;
}

/* This is a link target for one of the main content skip links */
#search-results {
	margin-top: calc(var(--site-header-height) * -1);
	padding-top: var(--site-header-height);
}

@media (--mq-v2-from-large) {
	#search-results {
		margin-top: calc(var(--site-header-height-from-medium) * -1);
		padding-top: var(--site-header-height-from-medium);
	}
}

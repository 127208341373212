/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.play-pause-toggle [icon-when-playing],
.play-pause-toggle [icon-when-paused] {
	display: none;
}

.play-pause-toggle[data-playing] [icon-when-playing] {
	display: block;
}

.play-pause-toggle:not([data-playing]) [icon-when-paused] {
	display: block;
}

.button-group {
	display: inline-flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 0 calc(var(--space-16) * -1); /* make up space for items' bottom margin */
	padding: 0;
	align-items: center;
}

.button-group.button-group--align-right {
	justify-content: flex-end;
	width: 100%;
}

.button-group__item {
	margin-right: var(--space-16);
	margin-bottom: var(--space-16);
}

.button-group--align-right .button-group__item {
	margin-right: 0;
	margin-left: var(--space-16);
}

.button-group__item--small {
	/* When using button--small, use this button-group */
	margin-right: var(--space-16);
	margin-bottom: var(--space-20);
}

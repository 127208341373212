/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.site-header {
	box-sizing: border-box;
	position: relative;
	top: 0;
	width: 100%;
	height: var(--site-header-height);
	z-index: 9;
}

@media (--mq-v2-from-large) {
	.site-header {
		position: sticky;
		height: var(--site-header-height-from-medium);
	}
}

.l-styleguide .site-header,
.sg-section .site-header {
	position: relative;
}

body[has-notice-bar] .site-header {
	height: var(--site-header-with-notice-bar-height);
}

@media (--mq-v2-from-large) {
	body[has-notice-bar] .site-header {
		height: var(--site-header-with-notice-bar-height-from-medium);
	}
}

.site-header,
.site-header * {
	overflow: visible; /* support fly-out menus */
}

.site-header__main {
	border-bottom: 1px solid var(--color-grey);
}

.site-header__inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: var(--site-header-bar-height);
}

.site-header__left,
.site-header__right {
	display: flex;
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
	align-items: center;
	position: relative;
	z-index: 2;
}

.site-header__left {
	justify-content: flex-start;
}

.site-header__left > *:not(:last-child) {
	margin-right: var(--space-32);
}

.site-header__right {
	justify-content: flex-end;
}

.site-header__right > *:not(:first-child) {
	margin-left: var(--space-32);
}

.site-header__logo {
	color: currentColor;
	display: block;
	transform: translateY(-1px);
	width: 5rem;
	height: 2.4375rem;
}

.site-header__logo .icon {
	display: block;
	width: 100%;
	height: 100%;
}

.site-header__left .site-header__navigation {
	margin-left: calc(var(--space-10) * -1); /* make up space from nav padding */
}

.site-header__right .site-header__navigation {
	margin-right: calc(var(--space-10) * -1); /* make up space from nav padding */
}

/* Title */
:root {
	--font-product-title-price--title: var(--font-weight-regular) var(--font-size-small) / var(--line-height-tight) var(--font-sans);
}

@media (--mq-v2-from-medium) {
	:root {
		--font-product-title-price--title: var(--font-weight-regular) var(--font-size-default) / var(--line-height-tight) var(--font-sans);
	}
}

@media (--mq-v2-from-xxlarge) {
	:root {
		--font-product-title-price--title: var(--font-weight-regular) var(--font-size-large) / var(--line-height-tight) var(--font-sans);
	}
}

/* Price */
:root {
	--font-product-title-price--price: var(--font-weight-bold) var(--font-size-small) / var(--line-height-tight) var(--font-sans);
}

@media (--mq-v2-from-medium) {
	:root {
		--font-product-title-price--price: var(--font-weight-bold) var(--font-size-default) / var(--line-height-tight) var(--font-sans);
	}
}

@media (--mq-v2-from-xxlarge) {
	:root {
		--font-product-title-price--price: var(--font-weight-bold) var(--font-size-large) / var(--line-height-tight) var(--font-sans);
	}
}

/* Sticky bar */
:root {
	--font-sticky-bar: var(--font-weight-regular) var(--font-size-default) / var(--line-height-tight) var(--font-sans);
}

/* mq-v2-from-xlarge */
@media (--mq-v2-from-xlarge) {
	:root {
		--font-sticky-bar: var(--font-weight-regular) var(--font-size-large) / var(--line-height-tight) var(--font-sans);
	}
}

.klaviyo-newsletter-subscribe-popup {
	display: none;
	opacity: 0;
	overflow: auto;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: var(--z-index-top-layer);
}

.klaviyo-newsletter-subscribe-popup[data-state="opening"],
.klaviyo-newsletter-subscribe-popup[data-state="open"],
.klaviyo-newsletter-subscribe-popup[data-state="closing"] {
	display: block;
	opacity: 1;
	pointer-events: auto;
}

.klaviyo-newsletter-subscribe-popup:focus {
	outline: none;
}

.klaviyo-newsletter-subscribe-popup__inner {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	min-height: 100%;
}

.klaviyo-newsletter-subscribe-popup__content {
	display: none;
	margin-block: var(--space-20);
	opacity: 0;
	position: relative;
}

.klaviyo-newsletter-subscribe-popup[data-state="opening"] .klaviyo-newsletter-subscribe-popup__content,
.klaviyo-newsletter-subscribe-popup[data-state="open"] .klaviyo-newsletter-subscribe-popup__content,
.klaviyo-newsletter-subscribe-popup[data-state="closing"] .klaviyo-newsletter-subscribe-popup__content {
	background-color: var(--color-white);
	display: block;
	padding: 0;
	width: calc(100% - var(--space-20--px) * 2);
	max-width: 330px;
	z-index: calc(var(--z-index-top-layer) + 1);
}

/* mq-v2-from-medium */
@media (--mq-v2-from-medium) {
	.klaviyo-newsletter-subscribe-popup[data-state="opening"] .klaviyo-newsletter-subscribe-popup__content,
	.klaviyo-newsletter-subscribe-popup[data-state="open"] .klaviyo-newsletter-subscribe-popup__content,
	.klaviyo-newsletter-subscribe-popup[data-state="closing"] .klaviyo-newsletter-subscribe-popup__content {
		max-width: 480px;
	}
}

.klaviyo-newsletter-subscribe-popup[data-state="open"] .klaviyo-newsletter-subscribe-popup__content {
	display: block;
	opacity: 1;
}

.klaviyo-newsletter-subscribe-popup[data-state="opening"] .klaviyo-newsletter-subscribe-popup__background,
.klaviyo-newsletter-subscribe-popup[data-state="open"] .klaviyo-newsletter-subscribe-popup__background,
.klaviyo-newsletter-subscribe-popup[data-state="closing"] .klaviyo-newsletter-subscribe-popup__background {
	background-color: var(--color-alpha-black-23);
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: var(--z-index-top-layer);
}

/* #region Content Hero */
.klaviyo-newsletter-subscribe-popup__content__hero {
	background-color: var(--color-yellow);
	box-sizing: border-box;
	display: flex;
	padding-inline: var(--space-64--px);
	padding-block: var(--space-48);
	align-items: center;
	position: relative;
	text-align: center;
	min-height: 7.5rem;
}

.klaviyo-newsletter-subscribe-popup__content__hero--with-banner {
	min-height: 13.75rem;
}

/* mq-v2-from-medium */
@media (--mq-v2-from-medium) {
	.klaviyo-newsletter-subscribe-popup__content__hero {
		min-height: 10rem;
	}

	.klaviyo-newsletter-subscribe-popup__content__hero--with-banner {
		min-height: 20rem;
	}
}

.klaviyo-newsletter-subscribe-popup__content__hero__image {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.klaviyo-newsletter-subscribe-popup__content__hero__image__source {
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.klaviyo-newsletter-subscribe-popup__content__hero__image__source {
		object-fit: cover;
		height: 100%;
	}
}

.klaviyo-newsletter-subscribe-popup__content__hero__image-overlay {
	background-color: rgb(0 0 0 / 0.3);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.klaviyo-newsletter-subscribe-popup__content__hero__title {
	font: var(--font-klaviyo-newsletter-subscribe-popup--title);
	outline: none; /* faux focus when modal dialog first opens */
	position: relative;
	text-wrap: balance;
	z-index: 2;
}

.klaviyo-newsletter-subscribe-popup__content__hero--with-banner .klaviyo-newsletter-subscribe-popup__content__hero__title[data-banner-image-is-dark] {
	color: var(--color-white);
}
/* #endregion Content Hero */

/* #region Close Button */
.klaviyo-newsletter-subscribe-popup__close-button {
	position: absolute;
	top: var(--space-12);
	right: var(--space-12--px);
	z-index: 3;
}

/* mq-v2-from-medium */
@media (--mq-v2-from-medium) {
	.klaviyo-newsletter-subscribe-popup__close-button {
		top: var(--space-16);
		right: var(--space-16--px);
	}
}

/* #endregion Close Button */

/* #region Content Body */
.klaviyo-newsletter-subscribe-popup__content__body {
}

.klaviyo-newsletter-subscribe-popup__content__body p {
	font: var(--font-klaviyo-newsletter-subscribe-popup--paragraph);
	margin-bottom: var(--space-16);
}
/* #endregion Content Body */

/* #region Form */
.klaviyo-newsletter-subscribe-popup .form {
	padding-top: 0;
}

.klaviyo-newsletter-subscribe-popup .form__input {
	background-color: var(--color-grey-light);
	border: 1px solid var(--color-grey-medium);
	font: var(--font-klaviyo-newsletter-subscribe-popup--form-input);
}

.klaviyo-newsletter-subscribe-popup .form__checkbox > label {
	font: var(--font-klaviyo-newsletter-subscribe-popup--form-label);
	text-align: left;
}

.klaviyo-newsletter-subscribe-popup .form__checkbox > label::before {
	background-color: var(--color-grey-light);
	border: 1px solid var(--color-grey-medium);
	box-sizing: border-box;
}
/* #endregion Form */

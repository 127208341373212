.video-progress {
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
}

/* Components below are generated in TS */
.mr-video-progress__bar,
.mr-video-progress__indicator {
	background-color: currentColor;
	position: absolute;
	left: 0;
	width: 100%;
}

.mr-video-progress__bar {
	top: 50%;
	height: 1px;
}

.mr-video-progress__indicator {
	position: relative;
	top: calc(50% - 15px);
	transform: translateX(0) translateZ(0);
	transform-origin: 0 0;
	width: 1px;
	height: 28px;
}

@media (--mq-v2-from-large) {
	.mr-video-progress__indicator {
		top: calc(50% - 21px);
		height: 40px;
	}
}

/* stylelint-disable-next-line @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
[scrubbing] .mr-video-progress__indicator {
	cursor: pointer; /* Fallback for IE11 */
	cursor: grabbing;
}

/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.mobile-menu {
	-webkit-overflow-scrolling: touch;
	color: var(--color-black);
	overflow-x: hidden;
	overflow-y: scroll;
	position: fixed;
	top: var(--site-header-bar-height);
	right: 0;
	left: 0;
	height: calc(100vh - var(--site-header-bar-height));
	z-index: 8;
}

@media (--mq-v2-from-large) {
	.mobile-menu {
		display: none;
	}
}

@media (--mq-v2-up-to-large) {
	.sg-section .mobile-menu {
		display: block;
		position: relative;
	}
}

body[has-notice-bar] .mobile-menu {
	top: calc((var(--site-header-bar-height) * 2) + 1px);
	height: calc(100vh - (var(--site-header-bar-height) * 2));
}

@media (--mq-v2-from-large) {
	body[has-notice-bar] .mobile-menu {
		top: calc(var(--site-header-with-notice-bar-height-from-medium) + 1px);
		height: calc(100vh - var(--site-header-with-notice-bar-height-from-medium));
	}
}

.mobile-menu__container {
	display: flex;
	flex-direction: column;
	overflow: auto;
	width: 100%;
	height: auto;
	min-height: 100%;
}

.mobile-menu__section {
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
}

.mobile-menu__section.mobile-menu__section--navigation {
	border-top: 1px solid var(--color-grey);
}

@media (max-height: 600px) {
	.mobile-menu__section:last-child {
		/* Ensure that browser UI on mobile does not cover the last items in the overlay */
		padding-bottom: 50px;
	}
}

.mobile-menu__navigation {
	list-style: none;
	margin: 0;
	padding: 0;
}

.mobile-menu__navigation.mobile-menu__navigation--link-list {
	line-height: 0.875rem;
	padding: var(--space-32) var(--space-16);
}

.mobile-menu__item {
	border-bottom: 1px solid var(--color-grey);
	box-sizing: border-box;
}

.mobile-menu__link {
	box-sizing: border-box;
	color: currentColor;
	display: block;
	padding: calc(var(--space-16) / 2) var(--space-16);
	text-decoration: none;
	width: 100%;
}

select.mobile-menu__link {
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	opacity: 0.4;
	outline: 0;
	padding-top: 0.625rem;
}

/* #region Main menu */
.mobile-menu__section .accordion__trigger .accordion__triangle {
	transform: rotate(90deg);
}

.mobile-menu__section .accordion__trigger[aria-expanded="true"] .accordion__triangle {
	transform: rotate(-90deg);
}

.mobile-menu__accordion__link {
	color: currentColor;
	display: block;
	line-height: 1;
	padding: var(--space-16) var(--space-32) var(--space-16) var(--space-16);
	text-decoration: underline;
}

.mobile-menu__accordion__link:hover {
	text-decoration: none;
}

.mobile-menu__accordion__link--active {
	font-weight: var(--font-weight-bold);
}

.mobile-menu__accordion__title {
	font-size: 1.125rem;
	line-height: 1;
}

.mobile-menu__main-wrapper {
	margin-bottom: var(--space-8);
}

@media (min-width: 414px) {
	.mobile-menu__main-wrapper {
		display: flex;
		flex-wrap: wrap;
	}
}

.mobile-menu__main:not(:last-child) {
	margin-bottom: var(--space-8);
}

.mobile-menu__main.mobile-menu__main--all {
	flex-basis: 100%;
	margin-top: 1.5rem;
	opacity: 0.45;
}

@media (min-width: 414px) {
	.mobile-menu__main {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (--mq-v2-from-medium) {
	.mobile-menu__main {
		flex-basis: 33.3333%;
	}
}

.mobile-menu__main__title {
	font-size: 0.625rem;
	letter-spacing: 1px;
	line-height: 2.4;
	margin-bottom: 0;
	text-transform: uppercase;
}

.mobile-menu__main__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.mobile-menu__main__link {
	color: currentColor;
	font-size: 1.25rem;
	letter-spacing: normal;
	line-height: 1.35;
	text-decoration: underline;
}

.mobile-menu__main__link:hover {
	text-decoration: none;
}

.mobile-menu__main__link--active {
	font-weight: var(--font-weight-bold);
}
/* #endregion Main menu */

/* #region Links */
.mobile-menu__link-list__item:not(:last-child) {
	margin-bottom: var(--space-16);
}

.mobile-menu__link-list__link {
	color: currentColor;
}

.mobile-menu__link-list__link:hover {
	text-decoration: none;
}

.mobile-menu__link-list__link--active {
	font-weight: var(--font-weight-bold);
}
/* #endregion Links */

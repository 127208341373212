.wrapper {
	box-sizing: border-box;
	overflow: hidden;
	padding-right: var(--space-16);
	padding-left: var(--space-16);
	position: relative;
	width: 100%;
}

@media (--mq-v2-from-large) {
	.wrapper {
		padding-right: var(--space-28);
		padding-left: var(--space-28);
	}
}

@media (--mq-v2-from-xlarge) {
	.wrapper {
		padding-right: var(--space-36);
		padding-left: var(--space-36);
	}
}

.site-footer {
	border-top: 1px solid var(--color-grey);
	overflow: hidden;
	width: 100%;
}

.site-footer__inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: var(--site-footer-height);
}

@media (--mq-v2-from-large) and (max-width: 1239px) {
	.site-footer__inner {
		align-items: stretch;
	}
}

.site-footer__left,
.site-footer__right {
	display: flex;
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
	align-items: center;
	position: relative;
	z-index: 2;
}

.site-footer__left {
	justify-content: flex-start;
}

@media (--mq-v2-from-large) and (max-width: 1239px) {
	.site-footer__left {
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
	}
}

.site-footer__left > *:not(:last-child) {
	margin-right: var(--space-32);
}

.site-footer__right {
	justify-content: flex-end;
}

@media (--mq-v2-from-large) and (max-width: 1239px) {
	.site-footer__right {
		flex-direction: column-reverse;
		justify-content: space-between;
		align-items: flex-end;
	}
}

.site-footer__right > *:not(:first-child) {
	margin-left: var(--space-32);
}

.site-footer__logo-container {
	display: inline-flex;
	align-items: center;
	min-height: var(--site-footer-height);
}

.site-footer__logo {
	color: currentColor;
	display: block;
	width: 2.875rem;
	height: 0.875rem;
}

.site-footer__logo .icon {
	display: block;
	width: 100%;
	height: 100%;
}

.site-footer__navigation {
	transform: translateY(1px);
}

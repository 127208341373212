.accordion {
	margin: 0;
	padding: 0;
	width: 100%;
}

.accordion.accordion--light {
	color: var(--color-alpha-foreground-55);
}

/* 🤮 */

.l-page__item:not(.l-page__item--no-pad) .accordion {
	border-top: 1px solid var(--color-grey);
	border-bottom: 1px solid var(--color-grey);
	margin-right: calc(var(--space-16) * -1);
	margin-left: calc(var(--space-16) * -1);
	width: calc(100% + (var(--space-16) * 2));
}

@media (--mq-v2-from-large) {
	.l-page__item:not(.l-page__item--no-pad) .accordion {
		margin-right: calc(var(--space-28) * -1);
		margin-left: calc(var(--space-28) * -1);
		width: calc(100% + (var(--space-28) * 2));
	}
}

@media (--mq-v2-from-xlarge) {
	.l-page__item:not(.l-page__item--no-pad) .accordion {
		margin-right: calc(var(--space-36) * -1);
		margin-left: calc(var(--space-36) * -1);
		width: calc(100% + (var(--space-36) * 2));
	}
}

.accordion__item:not(:last-child) {
	border-bottom: 1px solid var(--color-grey);
}

/* TODO: this should go, ideally */

.product-intro .accordion__item:last-child {
	border-bottom: 0;
}

.accordion__heading {
	font-size: inherit;
	line-height: inherit;
	margin: 0;
	padding: 0;
}

.accordion__trigger {
	background-color: inherit;
	border: 0;
	border-radius: 0;
	box-sizing: border-box;
	color: currentColor;
	display: block;
	outline: none;
	padding: var(--space-16) var(--space-32) var(--space-16) var(--space-16);
	position: relative;
	text-align: left;
	width: 100%;
}

@media (--mq-v2-from-large) {
	.accordion__trigger {
		padding-right: var(--space-48);
		padding-left: var(--space-28);
	}
}

@media (--mq-v2-from-xlarge) {
	.accordion__trigger {
		padding-left: var(--space-36);
	}
}

html:not(.no-js) .accordion__trigger:focus,
html:not(.no-js) .accordion__trigger:hover {
	cursor: pointer;
}

@media (--mq-v2-from-large) {
	.product-intro .accordion__trigger {
		padding: var(--space-28);
	}
}

@media (--mq-v2-from-xlarge) {
	.product-intro .accordion__trigger {
		padding-right: var(--space-36);
		padding-left: var(--space-36);
	}
}

.accordion__triangle {
	pointer-events: none;
	position: absolute;
	top: calc(50% - 5px);
	right: var(--space-16);
	transform-origin: 50% 50%;
	width: 15px;
	height: 10px;
}

@media (--mq-v2-from-large) {
	.accordion__triangle {
		right: var(--space-28);
	}
}

@media (--mq-v2-from-xlarge) {
	.accordion__triangle {
		right: var(--space-36);
	}
}

.accordion__trigger[aria-expanded="true"] .accordion__triangle {
	transform: rotate(90deg);
}

.accordion__triangle > svg {
	display: block;
	width: 100%;
	height: 100%;
}

.accordion__title {
	/* stylelint-disable-next-line declaration-property-value-no-unknown -- broken, must be fixed by a human */
	display: inline-lock;
	outline: none;
	pointer-events: none;
	position: relative;
}

.accordion--large .accordion__title {
	font-size: 1.5rem;
	line-height: 1.25;
}

.accordion--medium .accordion__title {
	font-size: 1.125rem;
	line-height: 1.25;
}

@media (--mq-v2-up-to-large) {
	.accordion--medium .accordion__title {
		font-size: 0.875rem;
		line-height: 1.25;
	}
}

/* Focus Visible + Fallbacks Pattern
	 * - focus state
	 * - focus:not(:focus-visible) reset
	 * - focus-visible state
	 */

.accordion__trigger:active .accordion__title,
.accordion__trigger:focus .accordion__title {
	background-color: var(--color-yellow);
}

.accordion__trigger:focus:not(:focus-visible) .accordion__title {
	background: transparent;
}

.accordion__title .accordion__trigger:focus-visible {
	background-color: var(--color-yellow);
}

html.no-js .accordion__title {
	cursor: text;
	pointer-events: auto;
	user-select: text;
}

/* #region Panel */
.accordion__panel {
	padding: 0 1rem 1rem;
}

.accordion__panel img {
	margin-bottom: 0.5rem;
	height: auto;
}

@media (--mq-v2-from-large) {
	.l-cart__summary .accordion__panel {
		padding: 0 var(--space-28) var(--space-28);
	}
}

@media (--mq-v2-from-xlarge) {
	.l-cart__summary .accordion__panel {
		padding: 0 var(--space-36) var(--space-36);
	}
}

@media (--mq-v2-from-large) {
	.product-intro .accordion__panel {
		padding: 0 var(--space-28) var(--space-28);
	}
}

@media (--mq-v2-from-xlarge) {
	.product-intro .accordion__panel {
		padding: 0 calc(var(--space-36) * 2) var(--space-36) var(--space-36);
	}
}

@media (--mq-v2-from-large) {
	.accordion__panel {
		padding: 0 var(--space-28) var(--space-16);
	}
}

@media (--mq-v2-from-xlarge) {
	.accordion__panel {
		padding: 0 var(--space-36) var(--space-16);
	}
}

html:not(.no-js, .js-loading-error) .accordion__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	html:not(.no-js, .js-loading-error) .accordion__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}
/* #endregion Panel */

.rated {
	display: flex;
}

.rated__star {
	color: var(--color-grey-dark);
	margin-right: 0.125rem;
	margin-left: 0.125rem;
}

.rated__star:first-child {
	margin-left: 0;
}

.rated__star:last-child {
	margin-right: 0;
}

.rated--1 .rated__star:nth-child(2),
.rated--1 .rated__star:nth-child(3),
.rated--2 .rated__star:nth-child(3) {
	color: #e5e5e5;
}

.rated__title {
	margin-right: 0.5rem;
	margin-bottom: 0;
}

.rated__star + .rated__title {
	margin-right: 0;
	margin-left: 0.5rem;
}

.payment-option {
	width: 100%;
}

.payment-option > input {
	display: none;
}

.payment-option__label {
	border-radius: 0;
	box-sizing: border-box;
	color: currentColor;
	display: flex;
	flex-wrap: wrap;
	font-family: inherit;
	font-size: inherit;
	outline: 0;
	padding: 0 0 0 1.875rem;
	align-items: center;
	position: relative;
	user-select: none;
}

.payment-option__label::before {
	background-color: var(--color-background-alternate);
	border-radius: 50%;
	content: "";
	display: block;
	position: absolute;
	top: 1.0625rem;
	left: 0;
	width: 1rem;
	height: 1rem;
}

.payment-option__label::after {
	border-bottom: 2px solid currentColor;
	border-left: 2px solid currentColor;
	box-sizing: border-box;
	content: "";
	display: none;
	position: absolute;
	top: 1.3125rem;
	left: 0.1875rem;
	transform: rotate(-45deg);
	width: 0.625rem;
	height: 0.375rem;
}

.payment-option__label:hover {
	cursor: pointer;
}

input:checked + .payment-option__label::after {
	display: block;
}

.payment-option__label__logo {
	display: block;
	margin-right: 0.75rem;
	margin-bottom: 0.75rem;
	width: 70px;
}

@media (--mq-v2-from-xlarge) {
	.payment-option__label__logo {
		margin-right: 1rem;
		margin-bottom: 1rem;
	}
}

@media (--mq-v2-from-xxlarge) {
	.payment-option__label__logo {
		margin-right: 1.25rem;
		margin-bottom: 1.25rem;
	}
}

.payment-option__info {
	background-color: var(--color-white);
	box-sizing: border-box;
	display: none;
	margin-bottom: 0.75rem;
	padding: var(--space-16);

	/* TODO why was this used? */

	/* .form__checkbox > label::before {
		background-color: var(--color-grey-light);
	} */
}

@media (--mq-v2-from-xlarge) {
	.payment-option__info {
		margin-bottom: 1rem;
	}
}

@media (--mq-v2-from-xxlarge) {
	.payment-option__info {
		margin-bottom: 1.25rem;
	}
}

input:checked ~ .payment-option__info {
	display: block;
}

.payment-option__info__image {
	display: block;
	margin-right: auto;
	margin-left: auto;
	width: 66.6666%;
	max-width: 250px;
	height: auto;
}

@media (--mq-v2-from-large) {
	.payment-option__info__image {
		width: 55%;
	}
}

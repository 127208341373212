.theme-site {
	/* Use for components that should follow the main site theme */
	background-color: var(--color-background);
	color: var(--color-foreground);
}

.theme-site-alternate {
	/* Use for components that should follow the alternate site theme */
	background-color: var(--color-background-alternate);
	color: var(--color-foreground);
}

.theme-black {
	background-color: var(--color-black);
	color: var(--color-white);
}

.theme-yellow {
	background-color: var(--color-yellow);
	color: var(--color-black);
}

.theme-text-transparent {
	opacity: 0.35;
}

.theme-text-alert {
	color: var(--color-alert);
}

/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.mute-toggle [icon-when-muted],
.mute-toggle [icon-when-unmuted] {
	display: none;
}

.mute-toggle[data-muted] [icon-when-muted] {
	display: block;
}

.mute-toggle:not([data-muted]) [icon-when-unmuted] {
	display: block;
}

.mute-toggle[data-has-no-audio-tracks] {
	display: none;
}

.video-controls {
	background: linear-gradient(to bottom, rgb(0 0 0 / 0) 0%, rgb(0 0 0 / 0.4) 100%);
	box-sizing: border-box;
	color: var(--color-white);
	display: flex;
	opacity: 0;
	padding: 0 var(--video-controls-space) var(--video-controls-space);
	pointer-events: none;
	position: absolute;
	bottom: 0;
	left: 0;
	transition: opacity 160ms linear;
	width: 100%;
	z-index: 8;
}

@media (--mq-v2-from-large) {
	.video-controls {
		padding-right: var(--video-controls-space-from-medium);
		padding-bottom: var(--video-controls-space-from-medium);
		padding-left: var(--video-controls-space-from-medium);
	}
}

@media (--mq-v2-from-xlarge) {
	.video-controls {
		padding-right: var(--video-controls-space-from-wide);
		padding-bottom: var(--video-controls-space-from-wide);
		padding-left: var(--video-controls-space-from-wide);
	}
}

/*
		Hiding with "is-hidden" is non overridable.
		Using !important here makes selectors simpler.
	*/

.video-controls.is-hidden {
	opacity: 0 !important;
	pointer-events: none !important;
}

/*
		Mr Video Swap shows another video without controls first.
		On click video's are swapped and the "swapped" attribute is set.
	 */

/* stylelint-disable-next-line @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
mr-video-swap:not([swapped]) .video-controls {
	opacity: 0 !important;
	pointer-events: none !important;
}

/* stylelint-disable-next-line @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
[native-controls] .video-controls {
	opacity: 0 !important;
	pointer-events: none !important;
}

/* stylelint-disable-next-line @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
mr-user-interacting[interacting] .video-controls {
	opacity: 1;
	pointer-events: auto;
}

/* stylelint-disable-next-line @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
mr-user-interacting:not([interacting]) .video-controls {
	cursor: default;
}

.video-controls__fullscreen-button-container,
.video-controls__mute-toggle-container,
.video-controls__progress-container,
.video-controls__playback-toggle-container {
	box-sizing: border-box;
	display: flex;
	flex-basis: auto;
	align-items: center;
	position: relative;
	height: var(--video-controls-height);
}

@media (--mq-v2-from-large) {
	.video-controls__fullscreen-button-container,
	.video-controls__mute-toggle-container,
	.video-controls__progress-container,
	.video-controls__playback-toggle-container {
		height: var(--video-controls-height-from-medium);
	}
}

@media (--mq-v2-from-xlarge) {
	.video-controls__fullscreen-button-container,
	.video-controls__mute-toggle-container,
	.video-controls__progress-container,
	.video-controls__playback-toggle-container {
		height: var(--video-controls-height-from-wide);
	}
}

.video-controls__fullscreen-button-container:not(:last-child) > *,
.video-controls__mute-toggle-container:not(:last-child) > *,
.video-controls__progress-container:not(:last-child) > *,
.video-controls__playback-toggle-container:not(:last-child) > * {
	padding-right: var(--space-16);
}

.video-controls__fullscreen-button-container,
.video-controls__mute-toggle-container,
.video-controls__playback-toggle-container {
	flex-grow: 0;
	flex-shrink: 0;
}

.video-controls__progress-container {
	flex-grow: 1;
	flex-shrink: 1;
	padding-top: 0.0625rem;
}

.video-controls__button {
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	color: currentColor;
	cursor: pointer;
	display: block;
	margin: 0;
	outline: 0;
	padding: 0;
	text-decoration: none;
	width: 1.8125rem;
	height: 1.8125rem;
}

.video-controls__button-label {
	display: block;
	width: 1.75rem;
	height: 1.75rem;
}

@media (--mq-v2-from-large) {
	.video-controls__button-label {
		width: 2.5rem;
		height: 2.5rem;
	}
}

.video-controls__button-label:hover {
	cursor: pointer;
}

.video-controls__button__icon {
	display: block;
	width: 100%;
	height: 100%;
}

:root {
	--font-cross-selling-hint--title: var(--font-weight-medium) var(--font-size-xlarge) / var(--line-height-tight) var(--font-sans);
}

/* mq-v2-from-large */
@media (--mq-v2-from-large) {
	:root {
		--font-cross-selling-hint--title: var(--font-weight-medium) var(--font-size-title-5) / var(--line-height-tight) var(--font-sans);
	}
}

.store-list {
	color: var(--color-grey-dark);
}

.store-list:not(:first-child) {
	margin-top: 3rem;
}

.store-list__legend:not(:last-child),
.store-list__store:not(:last-child) {
	margin-bottom: 2rem;
}

.store-list__store p:last-of-type {
	margin-bottom: 0.25rem;
}

.store-list__store p > a:not([class]) {
	display: inline;
	margin: 0;
	padding: 0;
}

.store-list__store p > a:not([class])::after {
	content: none;
	display: none;
}

.store-list__store a {
	color: currentColor;
}

.store-list__store__title {
	color: var(--color-black);
	margin: 0 0 0.75rem 0;
}

.store-list__store__info {
	line-height: 1.125rem;
}

.store-list__store__info > *:not([hidden]) {
	display: block;
}

.store-list__store__info:not(:last-child) {
	margin-bottom: 0.625rem;
}

.store-list__store__info a {
	/* Prevent consecutive a tags from sticking together */
	display: inline-block;
}

/* Title */
:root {
	--font-accordion--title: var(--font-weight-medium) var(--font-size-small) / var(--line-height-roomy) var(--font-sans);
}

/* mq-v2-from-medium */
@media (--mq-v2-from-medium) {
	:root {
		--font-accordion--title: var(--font-weight-medium) var(--font-size-default) / var(--line-height-roomy) var(--font-sans);
	}
}

/* Panel */
:root {
	--font-accordion--panel: var(--font-weight-regular) var(--font-size-mini) / var(--line-height-roomy) var(--font-sans);
}

/* mq-v2-from-medium */
@media (--mq-v2-from-medium) {
	:root {
		--font-accordion--panel: var(--font-weight-regular) var(--font-size-small) / var(--line-height-roomy) var(--font-sans);
	}
}

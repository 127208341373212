.cart {
	display: flex;
	flex-direction: column;
}

/* Product. */
.cart__product {
	background-color: var(--color-white);
	border-bottom: 1px solid var(--color-grey);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
}

.cart__product:last-child {
	border-bottom: 0;
}

.cart__product__header {
	display: flex;
}

/* Thumbnail. */
.cart__product__thumbnail {
	flex-basis: 40%;
	flex-grow: 0;
	flex-shrink: 0;
	overflow: hidden;
	position: relative;
}

@media (min-width: 768px) {
	.cart__product__thumbnail {
		flex-basis: 50%;
		flex-grow: 0;
	}
}

.cart__product__thumbnail img {
	border: var(--space-16) solid var(--color-white);
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
}

@media (min-width: 1024px) {
	.cart__product__thumbnail img {
		border-width: var(--space-28);
	}
}

@media (min-width: 1380px) {
	.cart__product__thumbnail img {
		border-width: var(--space-36);
	}
}

/* Info. */
.cart__product__info {
	box-sizing: border-box;
	display: flex;
	flex-basis: 60%;
	flex-grow: 0;
	flex-shrink: 0;
	padding-top: var(--space-36);
	padding-right: var(--space-16);
	padding-bottom: var(--space-36);
	justify-content: space-between;
	align-items: center;
}

@media (min-width: 768px) {
	.cart__product__info {
		flex-basis: 50%;
		min-height: 25vw;
	}
}

@media (min-width: 1024px) {
	.cart__product__info {
		padding-right: var(--space-28);
	}
}

@media (min-width: 1380px) {
	.cart__product__info {
		flex-basis: 40%;
		padding-right: var(--space-36);
	}
}

/* Quantity. */
.cart__product__quantity {
	display: flex;
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: var(--space-16);
	align-items: center;
}

.cart__product__quantity > *:not(:last-child) {
	margin-right: var(--space-10);
}

.cart__product__quantity-button {
	appearance: none;
	background: #d8d8d8; /* One-off darker shade of --color-background. */
	border-color: transparent;
	border-style: solid;
	border-width: 0;
	border-radius: 50%;
	box-shadow: none;
	box-sizing: border-box;
	color: currentColor;
	cursor: pointer;
	display: inline-block;
	font-size: 0.875rem;
	line-height: 1.375rem;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: relative;
	text-align: center;
	text-decoration: none;
	transform: translateY(-0.0625rem);
	transition:
		background-color 96ms linear,
		color 96ms linear;
	width: 1.375rem;
	height: 1.375rem;
}

@media (hover: hover) {
	.cart__product__quantity-button {
		line-height: 1.0625rem;
		width: 1.0625rem;
		height: 1.0625rem;
	}
}

.cart__product__quantity-button:focus,
.cart__product__quantity-button:hover {
	background-color: var(--color-black);
	color: var(--color-white);
}

.cart__product__quantity-button::after {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.cart__product__quantity-button.cart__product__quantity-button--add::after {
	content: "+";
	top: calc(50% - 0.0625rem); /* tweak visual alignment a little bit */
}

.cart__product__quantity-button.cart__product__quantity-button--subtract::after {
	content: "-";
	top: calc(50% - 0.03125rem); /* tweak visual alignment a little bit */
}

/* Pricing. */
.cart__product__pricing {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
	text-align: right;
}

/* Title. */
.cart__product__title {
	display: inline-block;
	margin-bottom: 0;
	min-width: 100%;
}

@media (max-width: 767px) {
	.cart__product__title {
		font-family: var(--font-sans);
		font-size: 0.875rem;
		font-weight: var(--font-weight-regular);
		line-height: 1.714;
	}
}

/* #region Cart Notice */
.cart__product__notice {
	background-color: var(--color-yellow);
	margin: 0 var(--space-16) var(--space-10) var(--space-16);
	padding: var(--space-8);
	width: fit-content;
}

@media (--mq-v2-from-large) {
	.cart__product__notice {
		margin-bottom: var(--space-20);
		margin-left: var(--space-20);
	}
}
/* #endregion Cart Notice */

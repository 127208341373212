/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.l-page {
	display: flex;
	align-items: stretch;
}

@media (max-width: 767px) {
	.l-page {
		flex-wrap: wrap;
	}
}

@media (min-width: 768px) {
	.l-page {
		min-height: 50vw;
	}
}

.l-page.l-page--full-height {
	min-height: calc(100vh - var(--site-header-height) - var(--site-footer-height));
}

@media (min-width: 1024px) {
	.l-page.l-page--full-height {
		min-height: calc(100vh - var(--site-header-height-from-medium) - var(--site-footer-height));
	}
}

body[has-notice-bar] .l-page.l-page--full-height {
	min-height: calc(100vh - var(--site-header-with-notice-bar-height) - var(--site-footer-height));
}

@media (min-width: 1024px) {
	body[has-notice-bar] .l-page.l-page--full-height {
		min-height: calc(100vh - var(--site-header-with-notice-bar-height-from-medium) - var(--site-footer-height));
	}
}

@media (min-width: 768px) {
	.l-page.l-page--half-ratio {
		min-height: 25vw;
	}
}

@media (min-width: 768px) {
	.l-page.l-page--quarter-ratio {
		min-height: 12.5vw;
	}
}

.l-page + .l-page {
	border-top: 0;
}

.l-page__column {
	display: flex;
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	flex-direction: column;
	position: relative;
	max-width: 100%;
}

@media (max-width: 767px) {
	.l-page__column.l-page__column--has-cover-image {
		min-height: 50vw;
	}
}

@media (min-width: 768px) {
	.l-page:not(.l-page--borderless) .l-page__column:not(:only-child):not(:last-child) {
		border-right: 1px solid var(--color-grey);
	}
}

@media (max-width: 767px) {
	.l-page:not(.l-page--store-locator) .l-page__column:not(:only-child):not(:first-child):not(:empty):not(.l-page__column--billing-address) {
		border-top: 1px solid var(--color-grey);
	}
}

.l-page__column:first-child:not(:only-child) {
	flex-grow: 1;
	flex-shrink: 1;
}

@media (min-width: 768px) {
	.l-page:not(.l-page--small-wide) .l-page__column:nth-child(2):last-child {
		flex-basis: 33.3333%;
		flex-grow: 0;
		flex-shrink: 0;
		max-width: 33.3333%;
	}
}

@media (min-width: 768px) {
	.l-page--small-wide .l-page__column:first-child {
		flex-basis: 33.3333%;
		flex-grow: 0;
		flex-shrink: 0;
		max-width: 33.3333%;
	}
}

@media (min-width: 768px) {
	.l-page--small-wide .l-page__column:nth-child(2):last-child {
		flex-basis: 33.3333%;
		flex-grow: 1;
		flex-shrink: 0;
		max-width: 33.3333%;
	}
}

@media (min-width: 768px) {
	.l-page__column:nth-child(2):not(:last-child),
	.l-page__column:nth-child(3):last-child {
		flex-basis: 33.3333%;
		flex-grow: 0;
		flex-shrink: 0;
		max-width: 33.3333%;
	}
}

@media (max-width: 767px) {
	.l-page__column:empty {
		display: none;
	}
}

/* Needed for IE11 full height cols */
@media (min-width: 768px) {
	.l-page--full-height .l-page__column {
		min-height: calc(100vh - var(--site-header-height) - var(--site-footer-height));
	}
}

@media (min-width: 1024px) {
	.l-page--full-height .l-page__column {
		min-height: calc(100vh - var(--site-header-height-from-medium) - var(--site-footer-height));
	}
}

@media (min-width: 768px) {
	body[has-notice-bar] .l-page--full-height .l-page__column {
		min-height: calc(100vh - var(--site-header-with-notice-bar-height) - var(--site-footer-height));
	}
}

@media (min-width: 1024px) {
	body[has-notice-bar] .l-page--full-height .l-page__column {
		min-height: calc(100vh - var(--site-header-with-notice-bar-height-from-medium) - var(--site-footer-height));
	}
}

@media (min-width: 768px) {
	.l-page--half-ratio .l-page__column {
		min-height: 25vw;
	}
}

@media (min-width: 768px) {
	.l-page--quarter-ratio .l-page__column {
		min-height: 12.5vw;
	}
}

.l-page__item {
	box-sizing: border-box;
}

.l-page__item:not(.l-page__item--no-pad) {
	padding: var(--space-16);
}

@media (min-width: 1024px) {
	.l-page__item:not(.l-page__item--no-pad) {
		padding: var(--space-28);
	}
}

@media (min-width: 1380px) {
	.l-page__item:not(.l-page__item--no-pad) {
		padding: var(--space-36);
	}
}

.l-page__item:not(:only-child):not(:first-child) {
	border-top: 1px solid var(--color-grey);
}

.l-page__item.hide:first-child + .l-page__item,
.l-page__item.u-hidden:first-child + .l-page__item {
	border-top: 0;
}

@media (min-width: 1024px) {
	.l-page__item.l-page__item--half-ratio {
		flex-basis: 25vw;
	}
}

@media (min-width: 1024px) {
	.l-page--account.l-page--half-ratio .l-page__item:first-child:not(:only-child) {
		flex-basis: 12.5vw;
	}
}

@media (min-width: 1024px) {
	.l-page--account.l-page--quarter-ratio .l-page__item:first-child:not(:only-child) {
		flex-basis: 6.25vw;
	}
}

.l-page--store-locator .l-page__item {
	height: 100%;
}

.l-page__item.l-page__item--no-pad-top {
	padding-top: 0;
}

.l-page__cover-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.l-page__cover-image__asset {
	display: block;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: auto;
	max-width: none;
	height: 100%;
}

@supports (object-fit: cover) {
	.l-page__cover-image__asset {
		object-fit: cover;
		left: 0;
		transform: none;
		width: 100%;
	}
}

.u-readable-max-width:is(h1, h2, h3, h4, h5, h6),
.u-readable-max-width :is(h1, h2, h3, h4, h5, h6) {
	text-wrap: balance;
}

.u-readable-max-width {
	margin: 0 auto;
	max-width: 20rem;
	max-width: 40ch;
}

@media (--mq-v2-from-small) {
	.u-readable-max-width {
		max-width: 24rem;
		max-width: 50ch;
	}
}

@media (--mq-v2-from-medium) {
	.u-readable-max-width {
		max-width: 28rem;
		max-width: 60ch;
	}
}

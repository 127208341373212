.hero {
	background-color: var(--color-background);
	box-sizing: border-box;
	color: var(--color-foreground);
	display: flex;
	padding: var(--space-16);
	justify-content: center;
	align-items: center;
	position: relative;
	text-align: center;
	min-height: 100vw;
}

@media (--mq-v2-from-medium) {
	.hero {
		min-height: 50vw;
	}
}

@media (--mq-v2-from-large) {
	.hero {
		padding: var(--space-28);
	}
}

@media (--mq-v2-from-xlarge) {
	.hero {
		padding: var(--space-36);
	}
}

.hero__content {
	position: relative;
	z-index: 2;
}

.hero__title {
	margin-bottom: 2.5rem;
}

@media (--mq-v2-from-medium) {
	.hero__title {
		margin-bottom: 4rem;
	}
}

@media (--mq-v2-from-large) {
	.hero__title {
		margin-bottom: 5rem;
	}
}

@media (--mq-v2-from-xlarge) {
	.hero__title {
		margin-bottom: 7rem;
	}
}

.hero__subtitle {
	margin: 0;
}

.hero__background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.hero__background__attachment {
	display: block;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: auto;
	max-width: none;
	height: 100%;
}

@supports (object-fit: cover) {
	.hero__background__attachment {
		object-fit: cover;
		left: 0;
		transform: none;
		width: 100%;
	}
}

.section-heading {
	background-color: var(--color-white);
	border-bottom: var(--border-product-grid);
	padding-inline: var(--space-8--px);
	padding-block: var(--space-16);
}

/* mq-v2-from-medium */
@media (--mq-v2-from-medium) {
	.section-heading {
		padding-inline: var(--space-16--px);
		padding-block: var(--space-24);
	}
}

/* mq-v2-from-xlarge */
@media (--mq-v2-from-xlarge) {
	.section-heading {
		padding-inline: var(--space-32--px);
	}
}

.section-heading__title {
	font: var(--font-section-heading--title);
}

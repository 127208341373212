.justify {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 100%;
}

.justify.justify--tl-bl,
.justify.justify--tl-br,
.justify.justify--tr-bl,
.justify.justify--tr-br {
	flex-direction: column;
	flex-wrap: wrap;
}

.justify.justify--bl-br {
	align-items: flex-end;
}

.justify--bl-br .justify__content:nth-child(2),
.justify--tl-tr .justify__content:nth-child(2),
.justify--tl-br .justify__content:nth-child(2),
.justify--tr-bl .justify__content:nth-child(1),
.justify--tr-br .justify__content:nth-child(1),
.justify--tr-br .justify__content:nth-child(2) {
	text-align: right;
}

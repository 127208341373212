.order-navigation {
}

@media (--mq-v2-up-to-medium) {
	.order-navigation__list {
		display: none;
	}
}

.order-navigation__overview__link {
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4MCAxMjAiPjxwYXRoIGQ9Ik03MCwxMDIuNkwxMy4yLDYwTDcwLDE3LjRWMTAyLjYgTTgwLDExMi4yVjcuOGMwLTQuMy00LjktNi43LTguMy00LjJMMi4xLDU1LjhjLTEuNCwxLTIuMSwyLjYtMi4xLDQuMVMwLjcsNjMsMi4xLDY0IGw2OS42LDUyLjJDNzUuMSwxMTguOSw4MCwxMTYuNSw4MCwxMTIuMkw4MCwxMTIuMnoiLz48L3N2Zz4K");
	background-position: left center;
	background-repeat: no-repeat;
	background-size: 8px 12px;
	box-sizing: border-box;
	display: block;
	padding-left: 20px;
	width: 100%;
}

@media (--mq-v2-from-medium) {
	.order-navigation__overview__link {
		display: none;
	}
}

.collection-navigation {
	position: relative;
}

@media (--mq-v2-from-small) {
	.collection-navigation {
		height: 100%;
	}
}

.collection-navigation__label {
	display: block;
	width: 100%;
}

.collection-navigation__checkbox:checked ~ .collection-navigation__label {
	display: none;
}

.collection-navigation__dropdown {
	border: 1px solid rgb(0 0 0 / 0);
	position: absolute;
	top: 0;
	right: 0;
	transform: rotate(90deg);
	transform-origin: 50% 50%;
	transition: border-color 96ms linear;
	width: 20px;
	height: 20px;
}

.collection-navigation__checkbox:checked ~ .collection-navigation__dropdown {
	transform: rotate(-90deg);
}

.collection-navigation__checkbox:focus ~ .collection-navigation__dropdown {
	/* fallback */
	border-color: currentColor;
}

.collection-navigation__checkbox:focus:not(:focus-visible) ~ .collection-navigation__dropdown {
	/* no visible focus for mouse users */
	border-color: transparent;
}

.collection-navigation__dropdown > svg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 6px;
	height: 10px;
}

@media (--mq-v2-from-small) {
	.collection-navigation__checkbox,
	.collection-navigation__dropdown,
	.collection-navigation__label {
		display: none;
	}
}

@media (--mq-v2-up-to-small) {
	.collection-navigation__checkbox:not(:checked) ~ .collection-navigation__nav {
		clip: rect(0 0 0 0);
		margin: -1px;
		overflow: hidden;
		position: absolute;
		white-space: nowrap;
		width: 1px;
		height: 1px;
	}
}

.collection-navigation__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.collection-navigation__item {
	box-sizing: border-box;
	color: currentColor;
	display: inline-block;
	font-size: inherit;
	line-height: inherit;
	margin: 0;
	width: 100%;
}

.collection-navigation__item.collection-navigation__item--current {
	font-weight: var(--font-weight-bold);
}

@media (--mq-v2-from-small) {
	.collection-navigation__item.collection-navigation__item--parent {
		display: none;
	}
}

.collection-navigation__link {
	color: currentColor;
	text-decoration: none;
}

@media (--mq-v2-up-to-small) {
	.collection-navigation__link {
		display: inline-block;
		line-height: 20px;
		margin: 0;
		height: 20px;
	}
}

.collection-navigation__link:hover {
	cursor: pointer;
}

.collection-navigation__link:active {
	text-decoration: underline;
}

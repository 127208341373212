.pagination {
	background-color: var(--color-white);
	padding-block: var(--space-16);
	text-align: center;
}

@media (--mq-v2-from-large) {
	.pagination {
		padding-block: var(--space-32);
	}
}

@media (--mq-v2-from-xlarge) {
	.pagination {
		padding-block: var(--space-40);
	}
}

.pagination__title {
	margin-bottom: var(--space-8);
}

/* #region List */
.pagination__list {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
}

.pagination__list :not(:first-child) {
	margin-left: var(--space-32--px);
}
/* #endregion List */

/* #region Link */
.pagination__list__item__link {
	color: currentColor;
	display: flex;
	font: var(--font-link);
	align-items: center;
	text-decoration-color: var(--color-alpha-black-55);
	text-underline-offset: 6px;
}

.pagination__list__item__link:hover {
	text-decoration-color: var(--color-black);
}

.pagination__list__item__link:active {
	background-color: var(--color-yellow);
}
/* #endregion Link */

/* #region Icon */
.pagination__list__item__link svg {
	width: 1rem;
	height: 1rem;
}

@media (--mq-v2-from-xsmall) {
	.pagination__list__item__link svg {
		width: 1.125rem;
		height: 1.125rem;
	}
}

@media (--mq-v2-from-medium) {
	.pagination__list__item__link svg {
		width: 1.25rem;
		height: 1.25rem;
	}
}

@media (--mq-v2-from-large) {
	.pagination__list__item__link svg {
		width: 1.375rem;
		height: 1.375rem;
	}
}

@media (--mq-v2-from-xlarge) {
	.pagination__list__item__link svg {
		width: 1.5rem;
		height: 1.5rem;
	}
}

.pagination__list__item__link--prev svg {
	padding-left: var(--space-4--px);
	transform: rotate(-180deg);
}

.pagination__list__item__link--next svg {
	padding-left: var(--space-4--px);
}
/* #endregion Icon */

.u-hidden,
.hide {
	/* Used by Shopify */
	display: none;
}

.u-visually-hidden {
	border: none !important;
	clip: rect(0 0 0 0) !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
}

@media (--mq-v2-up-to-large) {
	.u-visually-hide-up-to-medium {
		border: none !important;
		clip: rect(0 0 0 0) !important;
		margin: -1px !important;
		overflow: hidden !important;
		padding: 0 !important;
		position: absolute !important;
		white-space: nowrap !important;
		width: 1px !important;
		height: 1px !important;
	}
}

@media (--mq-v2-up-to-large) {
	.u-hide-up-to-medium {
		display: none !important;
	}
}

@media (--mq-v2-from-large) {
	.u-visually-hide-from-medium {
		border: none !important;
		clip: rect(0 0 0 0) !important;
		margin: -1px !important;
		overflow: hidden !important;
		padding: 0 !important;
		position: absolute !important;
		white-space: nowrap !important;
		width: 1px !important;
		height: 1px !important;
	}
}

@media (--mq-v2-from-large) {
	.u-hide-from-medium-up {
		display: none !important;
	}
}

.u-align-right {
	text-align: right;
}

.u-disabled {
	cursor: pointer;
	opacity: 0.5;
	pointer-events: none;
	user-select: none;
}

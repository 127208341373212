.cta-link {
	color: currentColor;
	display: inline-block;
	text-decoration: none;
}

.cta-link::before {
	content: "\2022\FE0E"; /* Bullet. */
	font-family: monospace; /* needs system font for font subsetting */
}

/** This component is used all over the place with links, buttons, spans, …
	 * Best to just check for elements here as opposed to adding more classes
	 * to work with and keep up to date, I think.
	 */

button.cta-link {
	appearance: none;
	background: none;
	border: 0;
	border-color: transparent;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.cta-link.cta-link--active {
	font-weight: var(--font-weight-bold);
}

.cta-link:active,
.cta-link:focus {
	outline: 0;
}

.cta-link:hover {
	cursor: pointer;
}

.cta-link__label {
	transition: opacity 96ms linear;
}

.cta-link:active .cta-link__label,
.cta-link:focus .cta-link__label {
	background-color: var(--color-yellow);
	color: var(--color-black);
}

.theme-yellow .cta-link:active .cta-link__label,
.theme-yellow .cta-link:focus .cta-link__label {
	background-color: var(--color-black);
	color: var(--color-white);
}

.cta-link:hover .cta-link__label {
	opacity: 0.7;
}

/* Uses the Shopify selector class twice to have higher specificity than Shopify styles */
@custom-selector :--button .button, .shopify-payment-button__button:not(.shopify-payment-button__button--branded);

:--button {
	/* Start reset. */
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	box-sizing: border-box;
	cursor: pointer;
	display: inline-block;
	outline: 0;
	position: relative;
	text-decoration: none;
	width: auto;
	min-height: var(--height-34);
	/* End reset. */

	/* Start custom button-look. */
	border-radius: 999px; /* As rounded as possible. */
	padding: 0.313rem 1rem 0.25rem;
	transition: color 96ms linear;
	/* End custom button-look. */

	/* Start colors. */
	background-color: var(--color-black);
	color: var(--color-white);
	/* End colors. */

	/* Start typography. */
	font-size: 1.125rem;
	font-style: normal;
	font-weight: var(--font-weight-medium);
	letter-spacing: normal;
	line-height: normal;
	text-align: center;
	/* End typography. */
}

@media (--mq-v2-from-large) {
	:--button {
		font-size: 1.25rem;
		padding: 0.438rem 1rem 0.375rem;
		min-height: var(--height-40);
	}
}

/* Disabled state. */
:--button[disabled] {
	background-color: var(--color-grey);
	color: var(--color-grey-dark);
	cursor: not-allowed;
}

/* Focus state. */
:--button:not([disabled]):not([aria-busy="true"]):focus {
	text-decoration: underline;
}

:--button::after {
	border: 1px solid #d200ff;
	border-radius: 999px; /* As rounded as possible. */
	content: "";
	display: none;
	filter: blur(1px);
	pointer-events: none;
	position: absolute;
	top: -3px;
	left: -3px;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
}

:--button:not([disabled]):not([aria-busy="true"]):focus::after {
	display: block;
}

:--button:not([disabled]):not([aria-busy="true"]):focus:not(:focus-visible)::after {
	display: none;
}

:--button:not([disabled]):not([aria-busy="true"]):focus-visible::after {
	display: block;
}

/* Hover state. */
:--button:not([disabled]):not([aria-busy="true"]):hover {
	background-color: var(--color-black);
	color: var(--color-yellow);
}

/* Theming. */
.theme-black :--button:not([disabled]),
.theme-dark :--button:not([disabled]) {
	background-color: var(--color-white);
	color: var(--color-black);
	transition: background-color 96ms linear;
}

.theme-black :--button:not([disabled]):not([aria-busy="true"]):hover,
.theme-dark :--button:not([disabled]):not([aria-busy="true"]):hover {
	background-color: var(--color-yellow);
	color: var(--color-black);
}

.theme-light :--button:not([disabled]) {
	background-color: var(--color-black);
	color: var(--color-white);
	transition: background-color 96ms linear;
}

.theme-light :--button:not([disabled]):not([aria-busy="true"]):hover {
	background-color: var(--color-black);
	color: var(--color-yellow);
}

/* #region Outlined variation. */
:--button.button--outlined {
	background-color: transparent;
	border: 2px solid currentColor;
	color: var(--color-black);
	padding: 0.188rem 1rem 0.188rem;
	transition:
		background-color 96ms linear,
		border-color 96ms linear,
		color 96ms linear;
}

@media (--mq-v2-from-large) {
	:--button.button--outlined {
		padding: 0.3125rem 1rem 0.375rem;
	}
}

:--button.button--outlined[disabled] {
	background-color: transparent;
	color: var(--color-grey-dark);
}

:--button.button--outlined:not([disabled]):not([aria-busy="true"]):hover {
	background-color: var(--color-yellow);
	color: var(--color-black);
}

/* Focus state. */
:--button.button--outlined::after {
	border-radius: 1.375rem;
	top: -5px;
	left: -5px;
	width: calc(100% + 8px);
	height: calc(100% + 8px);
}

/* Theming. */
.theme-black :--button.button--outlined:not([disabled]) {
	background-color: transparent;
	color: var(--color-white);
}

.theme-black :--button.button--outlined:not([disabled]):not([aria-busy="true"]):hover {
	border-color: var(--color-yellow);
}

.theme-yellow :--button.button--outlined:not([disabled]):not([aria-busy="true"]):hover {
	background-color: var(--color-white);
}
/* #endregion Outlined variation. */

/* #region Outlined variation WHITE */
:--button.button--outlined--white {
	border: 2px solid var(--color-white);
	color: var(--color-white);
}

:--button.button--outlined--white[disabled] {
	border: 2px solid var(--color-alpha-white-55);
	color: var(--color-alpha-white-55);
}

:--button.button--outlined--white:not([disabled]):not([aria-busy="true"]):hover {
	background-color: var(--color-white);
	color: var(--color-black);
}
/* #endregion Outlined variation WHITE */

/* #region Outlined variation for Desktop Only. */
@media (--mq-v2-from-large) {
	:--button.button--outlined-from-medium {
		background-color: transparent;
		border: 2px solid currentColor;
		color: var(--color-black);
		transition:
			background-color 96ms linear,
			border-color 96ms linear,
			color 96ms linear;
	}

	:--button.button--outlined-from-medium[disabled] {
		background-color: transparent;
		color: var(--color-grey-dark);
	}

	:--button.button--outlined-from-medium:not([disabled]):not([aria-busy="true"]):hover {
		background-color: var(--color-yellow);
		color: var(--color-black);
	}

	/* Focus state. */
	:--button.button--outlined-from-medium::after {
		border-radius: 1.375rem;
		top: -5px;
		left: -5px;
		width: calc(100% + 8px);
		height: calc(100% + 8px);
	}

	/* Theming. */
	.theme-black :--button.button--outlined-from-medium:not([disabled]) {
		background-color: transparent;
		color: var(--color-white);
	}

	.theme-black :--button.button--outlined-from-medium:not([disabled]):not([aria-busy="true"]):hover {
		border-color: var(--color-yellow);
	}
}
/* #endregion Outlined variation for Desktop Only. */

/* #region Small variation. */
:--button.button--small {
	font-size: 0.875rem;
	padding: 0.3125rem 0.625rem 0.375rem;
	min-height: unset; /* --height-30 does not exist */
	/* min-height: var(--height-30); */
}

:--button.button--small.button--outlined {
	padding: 0.188rem 0.625rem 0.25rem;
}
/* #endregion Small variation. */

/* #region Shopify payment button specifics */
.shopify-payment-button__container {
	margin-top: var(--space-8);
	min-height: 5.3125rem;
}

@media (min-width: 1280px) {
	.shopify-payment-button__container {
		margin-top: 10px;
	}
}
/* #endregion Shopify payment button specifics */

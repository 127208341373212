/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.hotspots {
	position: relative;
	width: 100%;
}

.hotspots img {
	width: 100%;
}

.hotspot {
	position: absolute;
}

.hotspot__area {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 3.125rem;
	height: 3.125rem;
}

/* #region Circle */
.hotspot__circle {
	background-color: var(--color-white);
	border: 2px solid var(--color-black);
	border-radius: 50%;
	display: block;
	pointer-events: none;
	width: var(--space-10);
	height: var(--space-10);
	z-index: 1;
}

@media (min-width: 768px) {
	.hotspot__circle {
		width: 0.8125rem;
		height: 0.8125rem;
	}
}

@media (min-width: 1024px) {
	.hotspot__circle {
		width: 1.0625rem;
		height: 1.0625rem;
	}
}
/* #endregion Circle */

/* #region Card */
.hotspot__card {
	background-color: var(--color-white);
	box-shadow: 0 6px 10px 0 rgb(0 0 0 / 0.3);
	color: var(--color-black);
	opacity: 0;
	padding: var(--space-10) 10px;
	pointer-events: none;
	position: absolute;
	top: 1.5625rem;
	left: 3.125rem;
	transition: opacity 128ms ease-in;
	width: max-content;
	max-width: calc(100vw / 2 - 3.125rem);
	z-index: 2;
}

@media (min-width: 768px) {
	.hotspot__card {
		box-shadow: none;
	}
}

.hotspot__area[anchor~="top"] .hotspot__card {
	top: 1.5625rem;
	bottom: initial;
}

.hotspot__area[anchor~="right"] .hotspot__card {
	left: 0;
	transform: translateX(-100%);
}

.hotspot__area[anchor~="bottom"] .hotspot__card {
	top: initial;
	bottom: 1.5625rem;
}

.hotspot__area[anchor~="left"] .hotspot__card {
	right: initial;
	left: 3.125rem;
}

.hotspot > [data-state="open"] .hotspot__card {
	color: var(--color-black);
	opacity: initial;
	pointer-events: initial;
	z-index: 3;
}

.hotspot:is(:focus, :focus-visible) .hotspot__card {
	color: var(--color-black);
	opacity: initial;
	pointer-events: initial;
	z-index: 4;
}
/* #endregion Card */

/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.countdown {
	background: var(--color-white);
	box-sizing: border-box;
	color: var(--color-black);
	display: block;
	padding: 16px;
	text-align: left;
	width: 100%;
	z-index: 8;
}

@media (min-width: 768px) {
	.countdown {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 320px;
	}
}

@media (min-width: 1240px) {
	.countdown {
		width: 410px;
	}
}

.countdown .button {
	margin-top: 10px;
}

.countdown .button a {
	text-decoration: none;
}

.countdown__part {
	display: flex;
	flex: 1;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	width: calc(calc(100vw - 32px) / 3);
	height: calc(calc(100vw - 32px) / 3);
	max-height: 200px;
}

@media (min-width: 768px) {
	.countdown__part {
		width: calc(288px / 3);
		height: calc(288px / 3);
	}
}

@media (min-width: 1240px) {
	.countdown__part {
		width: calc(378px / 3);
		height: calc(378px / 3);
	}
}

.countdown__part .countdown__part__number {
	font-size: 2.25rem;
	margin: 0;
}

@media (min-width: 1240px) {
	.countdown__part .countdown__part__number {
		font-size: 2.75rem;
	}
}

/* #region Hide / Show */
.countdown {
	opacity: 0;
}

.countdown[is-counting-down],
.countdown[has-finished] {
	opacity: 1;
	transition: opacity 96ms linear;
}

.countdown[is-counting-down] .countdown-is-running,
.countdown[has-finished] .countdown-is-finished {
	display: block;
}

.countdown[has-finished] .countdown-is-running,
.countdown[is-counting-down] .countdown-is-finished,
.countdown[has-finished] .countdown__numbers {
	display: none;
}

.countdown[is-counting-down] .countdown__numbers,
.countdown[is-counting-down] .countdown__cta.countdown-is-running,
.countdown[has-finished] .countdown__cta.countdown-is-finished {
	display: flex;
	justify-content: center;
}
/* #endregion Hide / Show */

/* #region no-js */
.no-js .countdown {
	opacity: 1;
}

.no-js .countdown__title.countdown-is-running {
	display: block;
}

.no-js .countdown__cta.countdown-is-running {
	display: flex;
	margin-top: 10px;
	justify-content: center;
}

.no-js .countdown__title.countdown-is-finished,
.no-js .countdown__cta.countdown-is-finished {
	display: none;
}

.no-js .countdown__numbers__no_js {
	display: block;
	font-size: 1.5rem;
	text-align: center;
}
/* #endregion no-js */

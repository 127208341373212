:root {
	--font-search-result-summary: var(--font-weight-medium) var(--font-size-large) / var(--line-height-tight) var(--font-sans);
}

@media (--mq-v2-from-medium) {
	:root {
		--font-search-result-summary: var(--font-weight-medium) var(--font-size-large) / var(--line-height-roomy) var(--font-sans);
	}
}

@media (--mq-v2-from-large) {
	:root {
		--font-search-result-summary: var(--font-weight-medium) var(--font-size-xlarge) / var(--line-height-tight) var(--font-sans);
	}
}

@media (--mq-v2-from-xxlarge) {
	:root {
		--font-search-result-summary: var(--font-weight-medium) var(--font-size-title-5) / var(--line-height-tight) var(--font-sans);
	}
}

.summary {
	color: var(--color-black);
}

.summary + .summary {
	border-top: 1px solid var(--color-grey);
	margin-top: var(--space-16);
	padding-top: var(--space-16);
}

.summary__row {
	display: flex;
	justify-content: space-between;
}

.summary__row.summary__row--product {
	margin-bottom: 0.4375rem;
	align-items: center;
}

.summary__row.summary__row--cart {
	align-items: flex-end;
}

.summary__label,
.summary__data {
	margin: 0;
	overflow: hidden;
}

.summary__row--product .summary__label,
.summary__row--product .summary__data {
	display: flex;
	align-items: center;
}

.summary__row--product .summary__label > span,
.summary__row--product .summary__data > span {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: calc(100vw - 175px);
}

@media (min-width: 768px) {
	.summary__row--product .summary__label > span,
	.summary__row--product .summary__data > span {
		max-width: calc(33.3333vw - 175px);
	}
}

@media (min-width: 1024px) {
	.summary__row--product .summary__label > span,
	.summary__row--product .summary__data > span {
		max-width: calc(33.3333vw - 225px);
	}
}

.summary__thumbnail {
	background-color: var(--color-background-alternate);
	border-top: 7px solid var(--color-background-alternate);
	border-right: 10px solid var(--color-background-alternate);
	border-bottom: 7px solid var(--color-background-alternate);
	border-left: 10px solid var(--color-background-alternate);
	box-sizing: border-box;
	margin-right: 0.75rem;
	width: 75px;
	height: auto;
}

@media (min-width: 1024px) {
	.summary__thumbnail {
		width: 110px;
	}
}

@supports (object-fit: contain) {
	.summary__thumbnail {
		object-fit: contain;
		object-position: center center;
		height: 56px;
	}

	@media (min-width: 1024px) {
		.summary__thumbnail {
			height: 75px;
		}
	}
}

.summary__data {
	text-align: right;
}

.accordion-v2 {
	margin: 0;
	margin-bottom: -1px;
	padding: 0;
	width: 100%;
}

.accordion-v2__item {
	border-top: 1px solid var(--color-grey-soft);
}

.accordion-v2__item:last-child {
	border-bottom: 1px solid var(--color-grey-soft);
}

.accordion-v2__item:hover .accordion-v2__title {
	color: var(--color-alpha-black-70);
}

.accordion-v2__item:hover .accordion-v2__triangle .icon {
	fill: var(--color-alpha-black-70);
}

.accordion-v2__heading {
	margin: 0;
	padding: 0;
}

.accordion-v2__title {
	display: inline-block;
	font: var(--font-accordion--title);
	pointer-events: none;
	position: relative;
}

/* #region Trigger */
.accordion-v2__trigger {
	-mrh-resets: button;
	display: flex;
	padding-inline: var(--space-8--px) 38px;
	padding-block: var(--space-12);
	justify-content: space-between;
	align-items: center;
	position: relative;
	width: 100%;
}

/* mq-v2-from-medium */
@media (--mq-v2-from-medium) {
	.accordion-v2__trigger {
		padding-inline: var(--space-16--px) 54px;
		padding-block: var(--space-12);
	}
}

/* mq-v2-from-xlarge */
@media (--mq-v2-from-xlarge) {
	.accordion-v2__trigger {
		padding-inline: var(--space-32--px) 70px;
		padding-block: var(--space-16);
	}
}
/* #endregion Trigger */

/* #region Icon */
.accordion-v2__triangle {
	pointer-events: none;
	position: absolute;
	top: calc(50% - 5px);
	right: var(--space-8--px);
	transform-origin: 50% 50%;
	width: 0.875rem;
	height: 0.875rem;
}

/* mq-v2-from-medium */
@media (--mq-v2-from-medium) {
	.accordion-v2__triangle {
		right: var(--space-16--px);
	}
}

/* mq-v2-from-xlarge */
@media (--mq-v2-from-xlarge) {
	.accordion-v2__triangle {
		right: var(--space-32--px);
	}
}

.accordion-v2__trigger[aria-expanded="true"] .accordion-v2__triangle {
	transform: rotate(180deg);
}

.accordion-v2__triangle > svg {
	display: block;
	width: 100%;
	height: 100%;
}
/* #endregion Icon */

/* #region States */
.accordion-v2__trigger:active .accordion-v2__title,
.accordion-v2__trigger:focus .accordion-v2__title {
	background-color: var(--color-yellow);
}

.accordion-v2__trigger:focus:not(:focus-visible) .accordion-v2__title {
	background: transparent;
}

.accordion-v2__title .accordion-v2__trigger:focus-visible {
	background-color: var(--color-yellow);
}
/* #endregion States */

/* #region Panel */
.accordion-v2__panel {
	padding-inline: var(--space-8--px);
	padding-block: 0 var(--space-12);
}

/* mq-v2-from-medium */
@media (--mq-v2-from-medium) {
	.accordion-v2__panel {
		padding-inline: var(--space-16--px);
		padding-block: 0 var(--space-16);
	}
}

/* mq-v2-from-xlarge */
@media (--mq-v2-from-xlarge) {
	.accordion-v2__panel {
		padding-inline: var(--space-32--px);
		padding-block: 0 var(--space-24);
	}
}

.accordion-v2__panel__content,
.accordion-v2__panel__content ul,
.accordion-v2__panel__content p {
	color: var(--color-alpha-black-70);
	font: var(--font-accordion--panel);
}

.accordion-v2__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	.accordion-v2__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}
/* #endregion Panel */

/* #region NoJS */
html:not(.no-js) .accordion-v2__trigger:focus,
html:not(.no-js) .accordion-v2__trigger:hover {
	cursor: pointer;
}

html.no-js .accordion-v2__panel {
	padding-inline: var(--space-8--px);
	padding-block: 0 var(--space-12);
}

/* mq-v2-from-medium */
@media (--mq-v2-from-medium) {
	html.no-js .accordion-v2__panel {
		padding-inline: var(--space-16--px);
		padding-block: 0 var(--space-16);
	}
}

/* mq-v2-from-xlarge */
@media (--mq-v2-from-xlarge) {
	html.no-js .accordion-v2__panel {
		padding-inline: var(--space-32--px);
		padding-block: 0 var(--space-24);
	}
}

html.no-js .accordion-v2__panel[hidden] {
	display: block;
}

@supports (content-visibility: hidden) {
	html.no-js .accordion-v2__panel {
		content-visibility: initial;
	}
}

html.no-js .accordion-v2__triangle {
	display: none;
}
/* #endregion NoJS */

/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.navigation-list {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
}

.navigation-list__item {
	position: relative;
}

@media (--mq-v2-up-to-small) {
	.site-header__mobile-navigation .navigation-list__item:nth-child(1n + 5) {
		display: none !important;
	}
}

/* #region List item with submenu */
.navigation-list__item--with-sub-menu::after {
	content: "";
	display: block;
	position: absolute;
	bottom: -0.5rem;
	left: 0;
	transform: translateY(0);
	transition: transform 192ms ease-out;
}

.navigation-list__item--with-sub-menu:hover::after,
.navigation-list__item--with-sub-menu[flyout-active]::after {
	background-color: var(--color-white);
	transform: translateY(-0.375rem);
	width: 100%;
	height: 0.5rem;
}

.navigation-list__item--with-sub-menu .navigation-list__link {
	padding-right: var(--space-4);
}
/* #endregion List item with submenu */

.navigation-list__button,
.navigation-list__link,
.navigation-list__note {
	color: currentColor;
	display: inline-flex;
	line-height: 1;
	padding-right: var(--space-10);
	padding-left: var(--space-10);
	align-items: center;
	min-height: var(--site-header-bar-height);
}

.navigation-list__button > *:not(:last-child),
.navigation-list__link > *:not(:last-child),
.navigation-list__note > *:not(:last-child) {
	display: inline-block;
	margin-right: 0.125rem;
}

.navigation-list__button,
.navigation-list__link,
.navigation-list__dropdown,
.navigation-list__sub-menu__link {
	text-decoration: none;
}

.navigation-list__button:active,
.navigation-list__link:active,
.navigation-list__dropdown:active,
.navigation-list__sub-menu__link:active {
	background-color: var(--color-yellow);
	outline: 0;
}

.navigation-list__button:focus,
.navigation-list__link:focus,
.navigation-list__dropdown:focus,
.navigation-list__sub-menu__link:focus {
	/* fallback */
	background-color: var(--color-yellow);
	outline: 0;
}

.navigation-list__button:focus:not(:focus-visible),
.navigation-list__link:focus:not(:focus-visible),
.navigation-list__dropdown:focus:not(:focus-visible),
.navigation-list__sub-menu__link:focus:not(:focus-visible) {
	/* no visible focus for mouse users */
	background-color: transparent;
	outline: 0;
}

.navigation-list__button:focus-visible,
.navigation-list__link:focus-visible,
.navigation-list__dropdown:focus-visible,
.navigation-list__sub-menu__link:focus-visible {
	/* visible focus for keyboard users */
	background-color: var(--color-yellow);
	outline: 0;
}

.navigation-list__button {
	appearance: none;
	background: none;
	border: 0;
	border-color: transparent;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: currentColor;
	cursor: pointer;
	display: inline-block;
	margin: 0;
	outline: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.navigation-list__button--dimmed {
	opacity: 0.55;
}

.navigation-list__button--dimmed:hover {
	opacity: 1;
}

.navigation-list__label {
	word-break: normal;
}

.navigation-list__item.is-active .navigation-list__label,
.navigation-list__item.current-menu-item .navigation-list__label {
	font-weight: var(--font-weight-bold);
}

.navigation-list__label .icon {
	display: inline-block;
	width: 1.125rem;
	height: 1.125rem;
}

@media (--mq-v2-from-large) {
	.navigation-list__label .icon {
		width: 0.875rem;
		height: 0.875rem;
	}
}

/* #region Dropdown */
.navigation-list__dropdown {
	appearance: none;
	background: none;
	border: none;
	margin: 0;
	padding: 0 var(--space-8) 0 0;
	min-height: var(--site-header-bar-height);
}

.navigation-list__dropdown__icon {
	display: inline-block;
	transform: rotate(90deg);
	transition: transform 192ms linear;
}

.navigation-list__dropdown__icon .icon {
	width: 8px;
	height: 8px;
}
/* #endregion Dropdown */

/* #region Sub menu */
.navigation-list__item:hover .navigation-list__sub-menu,
.navigation-list__sub-menu-fly-out[aria-expanded="true"] .navigation-list__sub-menu {
	display: flex;
}

.navigation-list__sub-menu {
	background-color: var(--color-white);
	border: 1px solid var(--color-grey);
	box-sizing: border-box;
	display: none;
	padding: 0.9375rem var(--space-24);
	justify-content: space-between;
	position: absolute;
	width: 14.25rem;
}

.navigation-list__sub-menu--left {
	left: calc(-1 * var(--space-24));
}

.navigation-list__sub-menu--right {
	right: calc(-1 * var(--space-24));
}

.navigation-list__sub-menu--2-columns {
	width: calc(calc(3 * var(--space-24)) + calc(2 * 11.25rem));
}

.navigation-list__sub-menu--3-columns {
	width: calc(calc(4 * var(--space-24)) + calc(3 * 11.25rem));
}

.navigation-list__sub-menu--4-columns {
	width: calc(calc(5 * var(--space-24)) + calc(4 * 11.25rem));
}

.navigation-list__sub-menu__block {
	flex-basis: 11.25rem;
	flex-grow: 0;
	flex-shrink: 0;
}

.navigation-list__sub-menu__title {
	font-size: 0.625rem;
	letter-spacing: 0.0625rem;
	line-height: 1.8;
	margin-bottom: 0;
	padding: var(--space-5) 0;
	text-transform: uppercase;
}

/** Short text, supports multiline.
 * e.g. "Free worldwide shipping on all order > €50"
 */
.navigation-list__sub-menu__note {
	border-bottom: 1px solid var(--color-grey);
	box-sizing: border-box;
	margin-bottom: var(--space-12);
	padding-top: var(--space-12);
	padding-bottom: var(--space-12);
	width: 100%;
}

.navigation-list__sub-menu__note:first-child {
	padding-top: 0;
}

.navigation-list__sub-menu__note:last-child {
	border-bottom: 0;
	margin-bottom: 0;
	padding-bottom: 0;
}

.navigation-list__sub-menu__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.navigation-list__sub-menu__list__item.is-active .navigation-list__sub-menu__link {
	font-weight: var(--font-weight-bold);
}

/* #region List Link */
.navigation-list__sub-menu__item--active {
	font-size: 1.125rem;
	font-weight: 700;
}

.navigation-list__sub-menu__region:not(:last-child) {
	margin-bottom: var(--space-10);
}

/** Actual menu items for links or actions
 * e.g. "Shipping Information" link, language picker
 */
.navigation-list__sub-menu__list__link-bullet,
.navigation-list__sub-menu__list__link {
	color: currentColor;
	text-decoration: none;
	width: auto;
}

.navigation-list__sub-menu__list__link-bullet:hover::before {
	content: "\2022\FE0E";
}

.navigation-list__sub-menu__list__link:hover {
	text-decoration: underline;
}

.navigation-list__sub-menu__list__link:active,
.navigation-list__sub-menu__list__link:focus {
	background-color: var(--color-yellow);
	outline: 0;
}

.navigation-list__sub-menu__list__link--disabled {
	color: var(--color-grey-dark);
}

.navigation-list__sub-menu__list__link--disabled:hover {
	text-decoration: none;
}

.navigation-list__sub-menu__list__link {
	font-size: 1.125rem;
}
/* #endregion List Link */

.navigation-list__sub-menu__link {
	color: currentColor;
	font-size: 1.25rem;
	line-height: 1.35;
	text-decoration: none;
}

.navigation-list__sub-menu__link:hover {
	text-decoration: underline;
}
/* #endregion Sub menu */

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--font-sans);
	font-size: 0.875rem;
	line-height: 1.714;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul:not([class]),
ol:not([class]) {
	margin-top: 0;
	margin-bottom: var(--space-16); /* 16px */
}

@media (--mq-v2-from-xlarge) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	ul:not([class]),
	ol:not([class]) {
		margin-bottom: var(--space-24); /* 24px */
	}
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
p:first-child,
ul:not([class]):first-child,
ol:not([class]):first-child {
	margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:not([class]):last-child,
ol:not([class]):last-child {
	margin-bottom: 0;
}

h1:only-child,
h2:only-child,
h3:only-child,
h4:only-child,
h5:only-child,
h6:only-child,
p:only-child,
ul:not([class]):only-child,
ol:not([class]):only-child {
	margin-top: 0;
	margin-bottom: 0;
}

.type-a {
	font-family: var(--font-sans);
	font-size: 1.5rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.25;
	text-transform: uppercase;
}

@media (--mq-v2-from-large) {
	.type-a {
		font-size: 2rem;
	}
}

@media (--mq-v2-from-xlarge) {
	.type-a {
		font-size: 3rem;
	}
}

h5,
h6,
.type-b {
	font-family: var(--font-sans);
	font-size: 1.125rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.444444;
}

@media (min-width: 1240px) {
	h5,
	h6,
	.type-b {
		font-size: 1.5rem;
		line-height: 1.333334;
	}
}

.type-c {
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.714;
}

@media (--mq-v2-from-large) {
	.type-c {
		font-size: 1.125rem;
		line-height: 1.25;
	}
}

@media (min-width: 1240px) {
	.type-c {
		font-size: 1.5rem;
	}
}

/* #region Headings */
h1,
h2,
h3,
h4 {
	font-family: var(--font-sans);
	font-weight: var(--font-weight-medium);
	line-height: var(--line-height-tight);
}

@media (--mq-v2-from-medium) {
	h1,
	h2,
	h3,
	h4 {
		line-height: var(--line-height-roomy);
	}
}
/* #endregion Headings */

/* #region H1 */
h1 {
	font-size: var(--font-size-xlarge); /* 20px */
}

@media (--mq-v2-from-large) {
	h1 {
		font-size: var(--font-size-title-5); /* 24px */
	}
}

@media (--mq-v2-from-xlarge) {
	h1 {
		font-size: var(--font-size-title-4); /* 32px */
	}
}

/* #endregion H1 */

/* #region H2 */
h2 {
	font-size: var(--font-size-large); /* 18px */
}

@media (--mq-v2-from-large) {
	h2 {
		font-size: var(--font-size-xlarge); /* 20px */
	}
}

@media (--mq-v2-from-xlarge) {
	h2 {
		font-size: var(--font-size-title-5); /* 24px */
	}
}
/* #endregion H2 */

/* #region H3, H4 */
h3,
h4 {
	font-size: var(--font-size-default); /* 16px */
}

@media (--mq-v2-from-large) {
	h3,
	h4 {
		font-size: var(--font-size-large); /* 18px */
	}
}

@media (--mq-v2-from-xlarge) {
	h3,
	h4 {
		font-size: var(--font-size-xlarge); /* 20px */
	}
}

h4 {
	color: var(--color-grey-dark);
}
/* #endregion H3, H4 */

/* #region Paragraph, Lists, Table */
p:not([class]),
ul:not([class]),
ol:not([class]),
td,
th {
	font-family: var(--font-sans);
	font-size: var(--font-size-small); /* 14px */
	font-weight: var(--font-weight-regular);
	line-height: var(--line-height-roomy);
}

@media (--mq-v2-from-xlarge) {
	p:not([class]),
	ul:not([class]),
	ol:not([class]),
	td,
	th {
		font-size: var(--font-size-default); /* 16px */
	}
}

/* #endregion Paragraph, Lists */

blockquote,
.wp-block-quote,
.type-d {
	font-family: var(--font-sans);
	font-size: 1.125rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.25;
}

blockquote > p:last-child:not(:only-child),
.wp-block-quote cite,
.type-e {
	/* body */
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.714;
}

blockquote,
i,
em {
	font-style: normal;
}

b,
strong {
	font-weight: var(--font-weight-bold);
}

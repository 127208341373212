.cta {
	color: currentColor;
	display: block;
}

.cta:not(:last-child) {
	margin-bottom: 1rem;
}

.cta.cta--align-right {
	text-align: right;
}

/* TODO: this should go, ideally */

.l-page--store-locator .cta {
	margin-top: 2rem;
}

.cta__title {
	font-size: inherit;
	line-height: inherit;
	margin-top: 0;
	margin-bottom: 0;
}

.cta__title[data-size="large"] {
	font-size: var(--font-size-title-3);
}

/* mq-v2-from-medium */
@media (--mq-v2-from-medium) {
	.cta__title[data-size="large"] {
		font-size: var(--font-size-title-2);
	}
}

/* mq-v2-from-xlarge */
@media (--mq-v2-from-xlarge) {
	.cta__title[data-size="large"] {
		font-size: var(--font-size-title-1);
	}
}

.cta__title:not(:only-child) {
	margin-bottom: var(--space-8);
}

.cta--horizontal .cta__title {
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
}

.cta__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.cta--horizontal .cta__list {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.cta--horizontal.cta--align-right .cta__list {
	justify-content: flex-end;
}

.cta__list__item:not(:last-child) {
	margin-bottom: var(--space-8);
}

.cta--horizontal:not(.cta--align-right) .cta__list__item:not(:last-child) {
	margin-right: var(--space-16);
}

.cta--horizontal.cta--align-right .cta__list__item:not(:first-child) {
	margin-left: var(--space-16);
}

.cta-link {
	color: currentColor;
	display: inline-block;
	text-decoration: none;
}

.cta-link::before {
	content: "\2022\FE0E"; /* Bullet. */
	font-family: monospace; /* needs system font for font subsetting */
}

/** This component is used all over the place with links, buttons, spans, …
	 * Best to just check for elements here as opposed to adding more classes
	 * to work with and keep up to date, I think.
	 */

button.cta-link {
	appearance: none;
	background: none;
	border: 0;
	border-color: transparent;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.cta-link.cta-link--active {
	font-weight: var(--font-weight-bold);
}

.cta-link:active,
.cta-link:focus {
	outline: 0;
}

.cta-link:hover {
	cursor: pointer;
}

.cta-link__label {
	transition: opacity 96ms linear;
}

.cta-link:active .cta-link__label,
.cta-link:focus .cta-link__label {
	background-color: var(--color-yellow);
}

.theme-yellow .cta-link__label .cta-link:active .cta-link__label,
.theme-yellow .cta-link__label .cta-link:focus .cta-link__label {
	background-color: var(--color-black);
	color: var(--color-white);
}

.cta-link:hover .cta-link__label {
	opacity: 0.7;
}

/* #region CTA List Button with hidden title */
.l-showcase__item:not(:focus):not(:hover) .cta__list__button--hidden-title:not(:focus) {
	border: none !important;
	clip: rect(0 0 0 0) !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
}
/* #endregion CTA List Button with hidden title */

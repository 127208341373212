:root {
	--font-collection-promotion-banner--title: var(--font-weight-medium) var(--font-size-default) / var(--line-height-tight) var(--font-sans);
}

@media (--mq-v2-from-xsmall) {
	:root {
		--font-collection-promotion-banner--title: var(--font-weight-medium) var(--font-size-large) / var(--line-height-tight) var(--font-sans);
	}
}

@media (--mq-v2-from-small) {
	:root {
		--font-collection-promotion-banner--title: var(--font-weight-medium) var(--font-size-xlarge) / var(--line-height-tight) var(--font-sans);
	}
}

@media (--mq-v2-from-large) {
	:root {
		--font-collection-promotion-banner--title: var(--font-weight-medium) var(--font-size-title-5) / var(--line-height-tight) var(--font-sans);
	}
}

@media (--mq-v2-from-xlarge) {
	:root {
		--font-collection-promotion-banner--title: var(--font-weight-medium) var(--font-size-title-3) / var(--line-height-tight) var(--font-sans);
	}
}

@media (--mq-v2-from-xxlarge) {
	:root {
		--font-collection-promotion-banner--title: var(--font-weight-medium) var(--font-size-title-4) / var(--line-height-tight) var(--font-sans);
	}
}

.form-validation {
	color: var(--color-black);
}

.form-validation.form-validation--error {
	color: var(--color-alert);
}

.form-validation.form-validation--success {
	color: var(--color-success);
}

.form-validation * {
	color: inherit;
	font-size: inherit;
	line-height: inherit;
}

.l-grid {
	display: flex;
	flex-wrap: wrap;
}

@supports (grid-auto-rows: 123px) {
	.l-grid {
		display: grid;
		flex: none;

		/* 3.125rem -> account for product title + price height */
		grid-column-gap: 0;
		grid-auto-rows: calc(37.5vw + 3.125rem);
		grid-template-columns: repeat(2, 50%);
	}

	@media (min-width: 568px) {
		.l-grid {
			grid-auto-rows: calc(25vw + 3.125rem);
			grid-template-columns: repeat(3, 33.33333%);
		}
	}

	@media (min-width: 768px) {
		.l-grid {
			grid-auto-rows: 25vw;
		}
	}

	@media (min-width: 1024px) {
		.l-grid.l-grid--4 {
			grid-auto-rows: 12.5vw;
			grid-template-columns: repeat(4, 25%);
		}
	}
}

@supports (grid-auto-rows: 123px) {
	.l-grid.l-grid--collection {
		grid-auto-rows: calc(72.1875vw + 3.125rem);
		grid-template-rows: auto;
		grid-template-columns: 100%;
	}

	@media (min-width: 768px) {
		.l-grid.l-grid--collection {
			grid-auto-rows: 37.5vw;
			grid-template-columns: repeat(2, 50%);
		}
	}

	@media (min-width: 1024px) {
		.l-grid.l-grid--collection {
			grid-auto-rows: 25vw;
			grid-template-columns: repeat(3, 33.33333%);
		}
	}
}

@supports (grid-auto-rows: 123px) {
	@media (max-width: 567px) {
		.l-grid.l-grid--limited-items {
			grid-auto-rows: calc(75vw + 3.125rem);
			grid-template-columns: 100vw;
		}
	}
}

.l-grid__item {
	flex-basis: 50%;
	height: calc(37.5vw + 3.125rem);
}

@media (min-width: 568px) {
	.l-grid__item {
		flex-basis: 33.3333%;
		height: calc(25vw + 3.125rem);
	}
}

@supports (grid-auto-rows: 123px) {
	.l-grid__item {
		height: auto;
	}
}

@media (max-width: 567px) {
	.l-grid--limited-items .l-grid__item {
		flex-basis: 100%;
		height: calc(75vw + 3.125rem);
	}
}

@supports (grid-auto-rows: 123px) {
	.l-grid--limited-items .l-grid__item {
		height: auto;
	}

	@media (max-width: 567px) {
		.l-grid--limited-items .l-grid__item {
			grid-row-end: span 1;
			grid-column-end: span 1;
		}
	}
}

@supports (grid-auto-rows: 123px) {
	@media (min-width: 568px) {
		.l-grid__item.l-grid__item--portrait {
			grid-row-end: span 2;
			grid-column-end: span 1;
		}
	}
}

.l-grid__item.l-grid__item--row {
	flex-basis: 100%;
	height: 100vw;
}

@media (min-width: 768px) {
	.l-grid__item.l-grid__item--row {
		height: 50vw;
	}
}

@supports (grid-auto-rows: 123px) {
	.l-grid__item.l-grid__item--row {
		flex: none;
		grid-row-end: span 2;
		grid-column-end: span 2;
	}

	@media (min-width: 568px) {
		.l-grid__item.l-grid__item--row {
			grid-column-end: span 3;
		}
	}
}

.l-grid__item.l-grid__item--page {
	border-right: 1px solid var(--color-grey);
	border-bottom: 1px solid var(--color-grey);
	box-sizing: border-box;
	padding: var(--space-16);
}

@media (min-width: 1024px) {
	.l-grid__item.l-grid__item--page {
		padding: var(--space-28);
	}
}

@media (min-width: 1380px) {
	.l-grid__item.l-grid__item--page {
		padding: var(--space-36);
	}
}

@media (max-width: 567px) {
	.l-grid__item.l-grid__item--page:nth-child(2n + 2) {
		border-right: 0;
	}
}

@media (min-width: 568px) and (max-width: 1023px) {
	.l-grid__item.l-grid__item--page:nth-child(3n + 3) {
		border-right: 0;
	}
}

@media (min-width: 1024px) {
	.l-grid__item.l-grid__item--page:nth-child(4n + 4) {
		border-right: 0;
	}
}

.l-grid__item.l-grid__item--page:last-child {
	border-bottom: 0;
}

@media (max-width: 567px) {
	.l-grid__item.l-grid__item--page:nth-last-child(2):nth-child(odd) {
		border-bottom: 0;
	}
}

@media (min-width: 568px) and (max-width: 1023px) {
	.l-grid__item.l-grid__item--page:nth-last-child(2):first-child,
	.l-grid__item.l-grid__item--page:nth-last-child(2):nth-child(2),
	.l-grid__item.l-grid__item--page:nth-last-child(2):nth-child(3n + 1),
	.l-grid__item.l-grid__item--page:nth-last-child(2):nth-child(3n + 2),
	.l-grid__item.l-grid__item--page:nth-last-child(3):first-child,
	.l-grid__item.l-grid__item--page:nth-last-child(3):nth-child(3n + 1) {
		border-bottom: 0;
	}
}

@media (min-width: 1024px) {
	.l-grid__item.l-grid__item--page:nth-last-child(2):first-child,
	.l-grid__item.l-grid__item--page:nth-last-child(2):nth-child(2),
	.l-grid__item.l-grid__item--page:nth-last-child(2):nth-child(3),
	.l-grid__item.l-grid__item--page:nth-last-child(2):nth-child(4n + 1),
	.l-grid__item.l-grid__item--page:nth-last-child(2):nth-child(4n + 2),
	.l-grid__item.l-grid__item--page:nth-last-child(2):nth-child(4n + 3),
	.l-grid__item.l-grid__item--page:nth-last-child(3):first-child,
	.l-grid__item.l-grid__item--page:nth-last-child(3):nth-child(2),
	.l-grid__item.l-grid__item--page:nth-last-child(3):nth-child(4n + 1),
	.l-grid__item.l-grid__item--page:nth-last-child(3):nth-child(4n + 2),
	.l-grid__item.l-grid__item--page:nth-last-child(4):first-child,
	.l-grid__item.l-grid__item--page:nth-last-child(4):nth-child(4n + 1) {
		border-bottom: 0;
	}
}

.l-grid__item.l-grid__item--collection,
.l-grid__item.l-grid__item--navigation {
	box-sizing: border-box;
	padding: 10px var(--space-16);
}

@media (min-width: 1024px) {
	.l-grid__item.l-grid__item--collection,
	.l-grid__item.l-grid__item--navigation {
		padding: var(--space-28);
	}
}

@media (min-width: 1380px) {
	.l-grid__item.l-grid__item--collection,
	.l-grid__item.l-grid__item--navigation {
		padding: var(--space-36);
	}
}

@media (max-width: 767px) {
	.l-grid__item.l-grid__item--collection {
		flex-basis: 100%;
		width: 100%;
	}
}

@supports (grid-auto-rows: 123px) {
	@media (max-width: 767px) {
		.l-grid__item.l-grid__item--collection {
			grid-column: 1 / span 1;
		}
	}

	@media (min-width: 768px) and (max-width: 1023px) {
		.l-grid__item.l-grid__item--collection {
			grid-column: 1 / span 2;
		}
	}
}

.l-grid__item.l-grid__item--padded {
	box-sizing: border-box;
	padding: var(--space-16);
}

@media (min-width: 1024px) {
	.l-grid__item.l-grid__item--padded {
		padding: var(--space-28);
	}
}

@media (min-width: 1380px) {
	.l-grid__item.l-grid__item--padded {
		padding: var(--space-36);
	}
}

.l-grid__item.l-grid__item--align-bottom {
	display: flex;
	align-items: flex-end;
	width: 100%;
	height: 100%;
}

@media (max-width: 413px) {
	.l-grid__item.l-grid__item--collection-title-card {
		min-height: 38.75vw;
	}
}

@media (max-width: 767px) {
	.l-grid__item.l-grid__item--collection-image {
		flex-basis: 100%;
	}
}

@media (min-width: 768px) {
	.l-grid__item.l-grid__item--collection-image {
		display: block;
		flex-basis: 66.6666%;
		height: calc(25vw + 3.125rem);
	}
}

@supports (grid-auto-rows: 123px) {
	@media (min-width: 768px) {
		.l-grid__item.l-grid__item--collection-image {
			height: auto;
		}
	}
}

@supports (grid-auto-rows: 123px) {
	@media (max-width: 767px) {
		.l-grid__item.l-grid__item--collection-image {
			grid-column-start: span 1;
		}
	}

	@media (min-width: 768px) and (max-width: 1023px) {
		.l-grid__item.l-grid__item--collection-image {
			/* tablet: column 1 and 2 */
			grid-column-start: span 2;
		}
	}

	@media (min-width: 1024px) {
		.l-grid__item.l-grid__item--collection-image {
			/* Desktop: column 2 and 3 */
			grid-column-start: span 2;
		}
	}
}

.l-grid__item.l-grid__item--collection-image .l-grid__item--collection-image__source {
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.l-grid__item.l-grid__item--collection-image .l-grid__item--collection-image__source {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
/** Container + Modal styles
 */
.navigation-overlay {
	display: none;
	opacity: 0;
	pointer-events: none;
	width: 100%;
}

.navigation-overlay[data-state="opening"],
.navigation-overlay[data-state="open"],
.navigation-overlay[data-state="closing"],
.l-styleguide .navigation-overlay[data-state] {
	display: block;
	opacity: 1;
	pointer-events: auto;
}

.navigation-overlay:focus {
	outline: none;
}

.navigation-overlay__header {
	border-bottom: 1px solid var(--color-grey);
}

.navigation-overlay__header__inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: var(--site-header-bar-height);
}

.navigation-overlay__header__left,
.navigation-overlay__header__right {
	display: flex;
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
	align-items: center;
	position: relative;
	z-index: 2;
}

.navigation-overlay__header__left {
	justify-content: flex-start;
}

.navigation-overlay__header__left > *:not(:last-child) {
	margin-right: var(--space-32);
}

.navigation-overlay__header__right {
	justify-content: flex-end;
}

.navigation-overlay__header__right > *:not(:first-child) {
	margin-left: var(--space-32);
}

.navigation-overlay__logo {
	color: currentColor;
	display: block;
	outline: none;
	transform: translateY(-1px);
	width: 5rem;
	height: 2.4375rem;
}

.navigation-overlay__logo:focus {
	background-color: var(--color-yellow);
}

.navigation-overlay__logo:focus:not(:focus-visible) {
	background-color: transparent;
}

.navigation-overlay__logo:focus-visible {
	background-color: var(--color-yellow);
}

.navigation-overlay__logo .icon {
	display: block;
	width: 100%;
	height: 100%;
}

.navigation-overlay__close-button {
	appearance: none;
	background: var(--color-background);
	border: 0 solid transparent;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: var(--color-foreground);
	cursor: pointer;
	display: inline-flex;
	line-height: 1;
	margin: 0;
	outline: 0;
	padding: 0 var(--space-10);
	align-items: center;
	min-height: var(--site-header-bar-height);
}

.navigation-overlay__close-button .icon {
	display: inline-block;
	width: 1.125rem;
	height: 1.125rem;
}

@media (--mq-v2-from-large) {
	.navigation-overlay__close-button .icon {
		width: 0.875rem;
		height: 0.875rem;
	}
}

.navigation-overlay__close-button:active {
	background-color: var(--color-yellow);
}

.navigation-overlay__close-button:focus {
	/* fallback */
	background-color: var(--color-yellow);
}

.navigation-overlay__close-button:focus:not(:focus-visible) {
	/* no visible focus for mouse users */
	background-color: transparent;
}

.navigation-overlay__close-button:focus-visible {
	/* visible focus for keyboard users */
	background-color: var(--color-yellow);
}

.navigation-overlay__inner {
	box-sizing: border-box;
	display: none;
	flex-direction: column;
	opacity: 0;
	overflow-y: auto;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: var(--z-index-navigation-dialog);
}

@media (--mq-v2-from-large) {
	.navigation-overlay__inner {
		flex-direction: row;
		overflow: hidden;
		align-items: stretch;
	}
}

body[has-notice-bar] .navigation-overlay__inner {
	top: var(--site-header-bar-height);
}

.navigation-overlay[data-state="opening"] .navigation-overlay__inner,
.navigation-overlay[data-state="open"] .navigation-overlay__inner,
.navigation-overlay[data-state="closing"] .navigation-overlay__inner,
.l-styleguide .navigation-overlay[data-state] .navigation-overlay__inner {
	display: flex;
	opacity: 1; /* might be set with animations in JS later */
}

.navigation-overlay[data-state="open"] .navigation-overlay__inner {
	display: flex;
}

.l-styleguide .navigation-overlay__inner {
	position: relative;
}

.navigation-overlay__header__right .navigation-overlay__header__navigation {
	margin-right: calc(var(--space-10) * -1); /* make up space from nav padding */
}

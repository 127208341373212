.social-navigation {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
}

.social-navigation__item {
	margin: 0 0.5rem;
}

.social-navigation__item:first-child {
	margin-left: 0;
}

.social-navigation__item:last-child {
	margin-right: 0;
}

.social-navigation__link {
	color: currentColor;
}

.social-navigation__link svg:not(.icon) {
	display: none;
}

.social-navigation__link .icon {
	display: block;
	padding: 0.3125rem 0;
	width: 0.875rem;
	height: 0.875rem;
}

.sidebar-navigation {
	list-style: none;
	margin: 0;
	padding: 0;
}

.sidebar-navigation + .sidebar-navigation {
	margin-top: var(--space-16);
}

@media (--mq-v2-from-large) {
	.sidebar-navigation + .sidebar-navigation {
		margin-top: var(--space-28);
	}
}

@media (--mq-v2-from-xlarge) {
	.sidebar-navigation + .sidebar-navigation {
		margin-top: var(--space-36);
	}
}

.sidebar-navigation__item {
	margin: 5px 0;
}

.sidebar-navigation__item:first-child {
	margin-top: 0;
}

.sidebar-navigation__item:last-child {
	margin-bottom: 0;
}

.sidebar-navigation__label,
.sidebar-navigation__link,
.sidebar-navigation__note {
	box-sizing: border-box;
	color: currentColor;
	display: inline-block;
	font-size: inherit;
	line-height: inherit;
	margin: 0;
}

.sidebar-navigation__link {
	color: currentColor;
	text-decoration: none;
	transition: opacity 96ms linear;
}

@media (--mq-v2-up-to-medium) {
	.sidebar-navigation__link {
		display: block;
	}
}

.sidebar-navigation__link:active,
.sidebar-navigation__link:focus {
	background-color: var(--color-yellow);
	outline: 0;
}

.theme-yellow .sidebar-navigation__link:active,
.theme-yellow .sidebar-navigation__link:focus {
	background-color: var(--color-black);
	color: var(--color-white);
}

.sidebar-navigation__link:hover {
	opacity: 0.7;
}

.site-footer .sidebar-navigation__link,
.site-header .sidebar-navigation__link {
	transition: color 96ms linear;
}

.site-footer .sidebar-navigation__link:hover,
.site-header .sidebar-navigation__link:hover {
	color: var(--color-grey-dark);
}

.sidebar-navigation__link.sidebar-navigation__link--active {
	font-weight: var(--font-weight-bold);
}

.sidebar-navigation__link.sidebar-navigation__link--align {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.sidebar-navigation__note {
	border-bottom: 1px solid var(--color-grey);
	margin-bottom: 0.75rem;
	padding-bottom: 0.75rem;
}

.sidebar-navigation__note:hover {
	cursor: default;
}

.sidebar-navigation__label {
	color: var(--color-grey);
}

.sidebar-navigation__label:hover {
	cursor: default;
}

:root {
	--color-background: #f0f0f0; /* Gray-5 */
	--color-background-alternate: #ffffff;
	--color-foreground: #000000;

	/* Neutral Colors  */
	--color-white: #ffffff; /* White */
	--color-alpha-white-55: rgb(255 255 255 / 0.55); /* White at 55% */
	/*  */
	--color-black: #000000; /* Black */
	--color-alpha-black-10: rgb(0 0 0 / 0.1); /* not in design library; current only used in the form__card class */
	--color-alpha-black-23: rgb(0 0 0 / 0.23); /* Black at 23% */
	--color-alpha-black-42: rgb(0 0 0 / 0.42); /* Black at 45% */
	--color-alpha-black-55: rgb(0 0 0 / 0.55); /* Black at 55% */
	--color-alpha-black-70: rgb(0 0 0 / 0.7); /* Black at 70% */
	--color-alpha-foreground-23: rgb(0 0 0 / 0.23); /* opacity match for grey-light */
	--color-alpha-foreground-42: rgb(0 0 0 / 0.42); /* opacity match for grey-dark */
	--color-alpha-foreground-55: rgb(0 0 0 / 0.55); /* Black at 55% */
	--color-alpha-foreground-70: rgb(0 0 0 / 0.7); /* Black at 70% */
	/* */
	--color-grey-dark: #939393; /* Gray-1 */
	--color-grey-light: #f0f0f0; /* Gray-5 */
	--color-grey-medium: #e4e4e4; /* Gray-4 */
	--color-grey-soft: #d8d8d8; /* Gray-3 */
	--color-grey: #c5c4c5; /* Gray-2 */

	/* Accent Colors **/
	--color-yellow: #fff799; /* Yellow */

	/* Thematic colors */
	--color-orange-yellow: #f8af39; /* age group kiddos */
	--color-purple: #e9b3d2; /* age group teens */
	/*  */
	--color-alert: #ff0000; /* Red */
	--color-success: #3fa743; /* Green */

	/* #region Typography */
	--font-sans: "KOMONO Neue", sans-serif;
	/*  */
	--font-size-micro: 0.625rem; /* 10px */
	--font-size-mini: 0.75rem; /* 12px */
	--font-size-small: 0.875rem; /* 14px */
	--font-size-default: 1rem; /* 16px */
	--font-size-large: 1.125rem; /* 18px */
	--font-size-xlarge: 1.25rem; /* 20px */
	--font-size-title-5: 1.5rem; /* 24px */
	--font-size-title-4: 2rem; /* 32px */
	--font-size-title-3: 2.25rem; /* 36px */
	--font-size-title-2: 2.75rem; /* 44px */
	--font-size-title-1: 3rem; /* 48px */
	/* #endregion Typography */

	/* #region Font Weights */
	--font-weight-regular: 400;
	--font-weight-medium: 500;
	--font-weight-bold: 700;
	/* #endregion Font Weights */

	/* #region Line-height */
	--line-height-tight: 1.3;
	--line-height-roomy: 1.5;
	--line-height-wide: 1.7;
	/* #endregion Line-height */

	/*  */
	--space-2: 0.125rem;
	--space-2--px: 2px;
	--space-4: 0.25rem;
	--space-4--px: 4px;
	--space-5: 0.3125rem;
	--space-6: 0.375rem;
	--space-6--px: 6px;
	--space-8: 0.5rem;
	--space-8--px: 8px;
	--space-10: 0.625rem;
	--space-10--px: 10px;
	--space-12: 0.75rem;
	--space-12--px: 12px;
	--space-16: 1rem;
	--space-16--px: 16px;
	--space-20: 1.25rem;
	--space-20--px: 20px;
	--space-24: 1.5rem;
	--space-24--px: 24px;
	--space-28: 1.75rem;
	--space-32: 2rem;
	--space-32--px: 32px;
	--space-36: 2.25rem;
	--space-36--px: 36px;
	--space-40: 2.5rem;
	--space-48: 3rem;
	--space-48--px: 48px;
	--space-64: 4rem;
	--space-64--px: 64px;
	/*  */
	--height-28: 28px;
	--height-34: 34px;
	--height-40: 40px;
	--height-48: 48px;
	/*  */
	--site-header-bar-height: 2.5rem; /* Height of a single site header bar. */
	/*  */
	--site-header-height: 2.5rem; /* 2 bars (main) + 1px border. */
	--site-header-height-from-medium: 2.5rem; /* 1 bar (main). */
	/*  */
	--site-header-with-notice-bar-height: 5.0625rem; /* 2 bars (notice + main) + 1px border. */
	--site-header-with-notice-bar-height-from-medium: 5rem; /* 2 bars (notice + main). */
	/*  */
	--site-footer-height: 2.5rem;
	/*  */
	--video-controls-height: 1.75rem;
	--video-controls-space: 1rem;
	/*  */
	--video-controls-height-from-medium: 2.5rem;
	--video-controls-space-from-medium: 1.75rem;
	/*  */
	--video-controls-height-from-wide: 2.5rem;
	--video-controls-space-from-wide: 2.25rem;
	/*  */
	--z-index-under-site-header-above-buttons: 2;
	--z-index-over-page: 990;
	--z-index-over-page--secondary: 991;
	--z-index-dialog: 999;
	--z-index-navigation-dialog: 9999;
	--z-index-top-layer: 999999;

	/* #region Product Grid */
	--border-product-grid: 1px solid var(--color-grey-soft);
	/* #endregion Product Grid */

	/* #region Border Radius */
	--border-radius-round: 99999px;
	/* #endregion Border Radius */

	/* #region Transitions */
	--transition-between-product-images--duration: 256ms;
	/* #endregion Transitions */
}

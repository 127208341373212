.notice-bar {
	box-sizing: border-box;
	display: flex;
	padding-top: var(--space-8);
	padding-bottom: var(--space-8);
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	min-height: var(--site-header-bar-height);
}

.l-styleguide .notice-bar,
.sg-section .notice-bar {
	position: relative;
}

.notice-bar:empty {
	display: none;
}

/* Inner. */
.notice-bar__inner {
	width: 100%;
}

/* Message. */
.notice-bar__message {
	font-size: 0;
}

@media (--mq-v2-up-to-medium) {
	.notice-bar__message__inline {
		display: none;
	}
}

@media (--mq-v2-from-medium) {
	.notice-bar__message__marquee {
		display: none;
	}
}

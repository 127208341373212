@media (--mq-v2-up-to-medium) {
	.account-navigation {
		display: flex;
		justify-content: space-between;
	}
}

.account-navigation__log-out-link,
.account-navigation__title {
	font-size: inherit;
	line-height: inherit;
}

@media (--mq-v2-up-to-medium) {
	.account-navigation__log-out-link,
	.account-navigation__title {
		margin: 0;
	}
}

.account-navigation__log-out-link {
	color: currentColor;
	text-decoration: none;
}

.account-navigation__log-out-link:active,
.account-navigation__log-out-link:focus {
	background-color: var(--color-yellow);
	outline: 0;
}

.theme-yellow .account-navigation__log-out-link:active,
.theme-yellow .account-navigation__log-out-link:focus {
	background-color: var(--color-black);
	color: var(--color-white);
}

.account-navigation__log-out-link:hover {
	opacity: 0.7;
}

@media (--mq-v2-up-to-medium) {
	.account-navigation__navigation {
		display: none;
	}
}

.account-accordion-link {
	background-color: var(--color-white);
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4MCAxMjAiPjxwYXRoIGQ9Ik0xMCwxMDIuNlYxNy40TDY2LjgsNjBMMTAsMTAyLjYgTTAsMTEyLjJjMCw0LjMsNC45LDYuNyw4LjMsNEw3Ny45LDY0YzEuNC0xLDIuMS0yLjYsMi4xLTQuMXMtMC43LTMuMS0yLjEtNC4xTDguMywzLjYgQzQuOSwxLjEsMCwzLjUsMCw3LjhWMTEyLjJMMCwxMTIuMnoiLz48L3N2Zz4K");
	background-position: calc(100% - var(--space-16)) 50%;
	background-repeat: no-repeat;
	background-size: 18px 12px;
	box-sizing: border-box;
	color: var(--color-black);
	display: block;
	text-decoration: none;
	width: 100%;
}

@media (--mq-v2-from-medium) {
	.account-accordion-link {
		display: none;
	}
}

.account-accordion-link.account-accordion-link--active {
	background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMjAgODAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEyMCA4MDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8cGF0aCBkPSJNMTcuMywxMGg4NS4yTDU5LjksNjYuOEwxNy4zLDEwIE03LjcsMEMzLjQsMCwxLDQuOSwzLjcsOC4zbDUyLjIsNjkuNmMxLDEuNCwyLjYsMi4xLDQuMSwyLjFzMy4xLTAuNyw0LjEtMi4xbDUyLjItNjkuNgoJYzIuNS0zLjQsMC4xLTguMy00LjItOC4zSDcuN0w3LjcsMHoiLz4KPC9zdmc+Cg==");
	background-position: calc(100% - var(--space-16) - 4px) 50%;
	background-size: 12px 18px;
	font-weight: var(--font-weight-bold);
}

.l-comparison-table {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
}

@media (--mq-v2-from-large) {
	.l-comparison-table.l-comparison-table--results {
		min-height: 50vw;
	}
}

.l-comparison-table__column {
	display: flex;
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	flex-direction: column;
}

@media (--mq-v2-from-medium) {
	.l-comparison-table__column {
		flex-basis: 33.3333%;
	}
}

@media (--mq-v2-up-to-medium) {
	.l-comparison-table__column:not(:only-child):not(:first-child):not(:empty) {
		border-top: 1px solid var(--color-grey);
	}
}

.l-comparison-table__row {
	box-sizing: border-box;
}

.l-comparison-table__row:not(.l-comparison-table__row--product) {
	padding: var(--space-16);
}

@media (--mq-v2-from-large) {
	.l-comparison-table__row:not(.l-comparison-table__row--product) {
		padding: var(--space-16) var(--space-28);
	}
}

@media (--mq-v2-from-xlarge) {
	.l-comparison-table__row:not(.l-comparison-table__row--product) {
		padding: var(--space-16) var(--space-36);
	}
}

@media (--mq-v2-from-medium) {
	.l-comparison-table__column:not(:nth-child(3n + 3)) .l-comparison-table__row:not(:first-child) {
		border-right: 1px solid var(--color-grey);
	}
}

.l-comparison-table__row:not(:only-child):not(:first-child) {
	border-top: 1px solid var(--color-grey);
}

.l-comparison-table__row.l-comparison-table__row--product {
	overflow: hidden;
	height: 50vw;
}

@media (--mq-v2-from-medium) {
	.l-comparison-table__row.l-comparison-table__row--product {
		height: 25vw;
	}
}

.l-comparison-table__row.l-comparison-table__row--price {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.l-comparison-table__row.l-comparison-table__row--price * {
	margin: 0;
}

.l-comparison-table__row.l-comparison-table__row--description {
	color: var(--color-grey-dark);
	flex-grow: 1;
}

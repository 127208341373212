.klaviyo-newsletter-subscribe {
	box-sizing: border-box;
	display: flex;
	padding: var(--space-16);
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
	min-height: 50vw;
}

@media (min-width: 768px) {
	.klaviyo-newsletter-subscribe {
		min-height: 350px;
	}
}

@media (min-width: 1024px) {
	.klaviyo-newsletter-subscribe {
		padding: var(--space-28);
	}
}

@media (min-width: 1380px) {
	.klaviyo-newsletter-subscribe {
		padding: var(--space-36);
		min-height: 370px;
	}
}

.klaviyo-newsletter-subscribe-popup .klaviyo-newsletter-subscribe {
	padding-inline: var(--space-24--px);
	padding-block: var(--space-24);
	min-height: auto;
}

@media (min-width: 768px) {
	.klaviyo-newsletter-subscribe-popup .klaviyo-newsletter-subscribe {
		padding-inline: var(--space-32--px);
		padding-block: var(--space-32);
	}
}

.klaviyo-newsletter-subscribe__container {
	max-width: 300px;
}

.klaviyo-newsletter-subscribe-popup .klaviyo-newsletter-subscribe__container {
	max-width: none;
}

.klaviyo-newsletter-subscribe__form__messages {
	background-color: var(--color-success);
	display: flex;
	padding-inline: var(--space-16--px);
	padding-block: var(--space-16);
	align-items: center;
	text-align: left;
}

.klaviyo-newsletter-subscribe__form__messages__icon {
	margin-right: var(--space-16--px);
}

.klaviyo-newsletter-subscribe__form__messages__icon .icon {
	display: block;
	width: 1.25rem;
	height: 1.25rem;
}

.klaviyo-newsletter-subscribe__form__messages[hidden] {
	display: none;
}

.klaviyo-newsletter-subscribe__form__messages--error {
	background-color: var(--color-alert);
	color: var(--color-white);
}

.klaviyo-newsletter-subscribe__form input:disabled,
.klaviyo-newsletter-subscribe__form input:disabled + label {
	opacity: 0.6;
}

.klaviyo-newsletter-subscribe__form .button:disabled {
	background-color: var(--color-grey-light);
	color: var(--color-alpha-black-23);
}

.klaviyo-newsletter-subscribe__form__button {
	margin-top: var(--space-24);
}

/* mq-from-medium */
@media (--mq-v2-from-large) {
	.klaviyo-newsletter-subscribe__form__button {
		margin-top: var(--space-32);
	}
}

/* #region Form */
.klaviyo-newsletter-subscribe .form__row:not(:last-child) {
	margin: 0;
	margin-bottom: var(--space-16);
}

.klaviyo-newsletter-subscribe .form__checkbox {
	margin-bottom: 0;
}
/* #endregion Form */

.search-result-summary {
	background-color: var(--color-white);
	border-bottom: var(--border-product-grid);
	padding: var(--space-16) var(--space-8--px);
}

@media (--mq-v2-from-large) {
	.search-result-summary {
		padding: var(--space-32) var(--space-16--px);
	}
}

@media (--mq-v2-from-xlarge) {
	.search-result-summary {
		padding: var(--space-40) var(--space-32--px);
	}
}

.search-result-summary__title {
	font: var(--font-search-result-summary);
}

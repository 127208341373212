:root {
	--font-product-selling-point: var(--font-weight-regular) var(--font-size-small) / var(--line-height-roomy) var(--font-sans);
}

/* mq-v2-from-large */
@media (--mq-v2-from-large) {
	:root {
		--font-product-selling-point: var(--font-weight-regular) var(--font-size-default) / var(--line-height-roomy) var(--font-sans);
	}
}

/* This component assumes parent container to fill */
.video-scrub {
	cursor: pointer; /* Fallback for IE11 */
	cursor: grab;
	display: block;
	width: 100%;
	height: 100%;
}

/* stylelint-disable-next-line @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.video-scrub[scrubbing] {
	cursor: pointer; /* Fallback for IE11 */
	cursor: grabbing;
}

.billing-info {
	width: 100%;
}

.billing-info__checkbox {
	display: none;
}

.billing-info__label {
	border-radius: 0;
	box-sizing: border-box;
	color: currentColor;
	display: block;
	font-family: inherit;
	font-size: inherit;
	outline: 0;
	padding: 0 0 0 1.875rem;
	position: relative;
	user-select: none;
	width: 100%;
}

.billing-info__label::before {
	background-color: var(--color-white);
	border-radius: 0;
	content: "";
	display: block;
	position: absolute;
	top: 0.1875rem;
	left: 0;
	width: 1rem;
	height: 1rem;
}

.billing-info__label::after {
	border-bottom: 2px solid var(--color-black);
	border-left: 2px solid var(--color-black);
	box-sizing: border-box;
	content: "";
	display: none;
	position: absolute;
	top: 0.4375rem;
	left: 0.1875rem;
	transform: rotate(-45deg);
	width: 0.625rem;
	height: 0.375rem;
}

.billing-info__label:hover {
	cursor: pointer;
}

.billing-info__checkbox:checked ~ .billing-info__label::after,
.billing-info__checkbox:checked ~ .l-page .billing-info__label::after {
	display: block;
}

.billing-info__form {
	display: none;
}

.billing-info__checkbox:not(:checked) ~ .billing-info__form,
.billing-info__checkbox:not(:checked) ~ .l-page .billing-info__form {
	display: block;
	padding-top: var(--space-16);
}

@media (--mq-v2-from-large) {
	.billing-info__checkbox:not(:checked) ~ .billing-info__form,
	.billing-info__checkbox:not(:checked) ~ .l-page .billing-info__form {
		padding-top: var(--space-28);
	}
}

@media (--mq-v2-from-xlarge) {
	.billing-info__checkbox:not(:checked) ~ .billing-info__form,
	.billing-info__checkbox:not(:checked) ~ .l-page .billing-info__form {
		padding-top: var(--space-36);
	}
}

.l-404 {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 100vh;
}

.l-404__wrapper {
	margin: 0 auto;
	padding: var(--space-16);
	max-width: 650px;
}

@media (--mq-v2-from-large) {
	.l-404__wrapper {
		padding: var(--space-28);
	}
}

@media (--mq-v2-from-xlarge) {
	.l-404__wrapper {
		padding: var(--space-36);
	}
}

.l-404__logo .icon {
	display: inline-block;
	max-width: 200px;
}

.overlay {
	background-color: transparent;
	box-sizing: border-box;
	content-visibility: hidden;
	display: none;
	opacity: 0;
	overflow: hidden;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: var(--z-index-top-layer);
}

.overlay[data-state="opening"],
.overlay[data-state="open"],
.overlay[data-state="closing"] {
	content-visibility: visible;
	display: block;
	pointer-events: auto;
}

.overlay[data-state="open"] {
	opacity: 1;
}

.overlay:focus {
	outline: none;
}

/* Container. */
.overlay__container {
	overflow: visible;
	position: absolute;
	top: calc(var(--space-16) + 3rem);
	left: var(--space-16);
	width: calc(100% - (var(--space-16) * 2)); /* Full width - padding. */
	max-width: 1280px;
	height: calc(100% - calc(var(--space-16) * 2) - 3rem); /* Full height - padding. */
	z-index: 3;
}

@media (--mq-v2-from-medium) {
	.overlay__container {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 678px;
		height: calc(100vh - 90px);
		max-height: 678px;
	}
}

@media (min-width: 1280px) {
	.overlay__container {
		width: 740px;
	}
}

@media (--mq-v2-from-xlarge) {
	.overlay__container {
		width: 800px;
		max-height: 690px;
	}
}

@media (--mq-v2-from-xxlarge) {
	.overlay__container {
		width: 920px;
		max-height: 768px;
	}
}

/* Scroll container. */
.overlay__scroll-container {
	overflow-x: hidden;
	overflow-y: auto;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Inner. */
.overlay__inner {
	height: auto;
}

@media (--mq-v2-up-to-medium) {
	.overlay__inner {
		min-height: 100%;
	}
}

/* Close button. */
.overlay__close-button {
	appearance: none;
	background: transparent;
	border: 0 solid transparent;
	border-radius: 0;
	box-shadow: none;
	box-sizing: content-box;
	color: var(--color-foreground);
	cursor: pointer;
	display: inline-flex;
	line-height: 1;
	margin: 0;
	outline: 0;
	padding: var(--space-16);
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
}

.overlay__close-button:active {
	background-color: var(--color-yellow);
}

.overlay__close-button:focus {
	/* fallback */
	background-color: var(--color-yellow);
}

.overlay__close-button:focus:not(:focus-visible) {
	/* no visible focus for mouse users */
	background-color: transparent;
}

.overlay__close-button:focus-visible {
	/* visible focus for keyboard users */
	background-color: var(--color-yellow);
}

/* @media (--mq-v2-from-small) {
	.overlay__close-button {
		padding: var(--space-10);
		right: calc(var(--space-10) * -1);
		top: calc(var(--space-36) * -1);
	}
} */
@media (--mq-v2-from-xlarge) {
	.overlay__close-button {
		padding: var(--space-28);
	}
}

.overlay__close-button .icon {
	display: inline-block;
	width: 1.125rem;
	height: 1.125rem;
}

@media (--mq-v2-from-large) {
	.overlay__close-button .icon {
		width: 0.875rem;
		height: 0.875rem;
	}
}

/* White version */
@media (--mq-v2-from-small) {
	.overlay__close-button--white {
		color: var(--color-background);
	}
}

/* Background close button. */
.overlay__background-close-button {
	/* button reset */
	appearance: none;
	background: none;
	border-color: transparent;
	border-style: solid;
	border-width: 0;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: currentColor;
	display: inline-block;
	font-size: 1.125rem;
	line-height: 1.25;
	margin: 0;
	outline: 0;
	padding: 0;
	text-decoration: none;
	/* button reset */

	background-color: var(--color-alpha-black-55);
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.video {
	display: block;
	overflow: hidden;
	position: relative;
}

.l-showcase__item .video {
	position: absolute !important;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.l-styleguide .video {
	min-height: 50vw;
}

.video__label {
	box-sizing: border-box;
	color: inherit;
	padding: var(--space-16);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 3;
}

@media (--mq-v2-from-large) {
	.video__label {
		padding: var(--space-28);
	}
}

@media (--mq-v2-from-xlarge) {
	.video__label {
		padding: var(--space-36);
	}
}

.l-showcase__item--bl .video__label,
.l-showcase__item--br .video__label,
.l-showcase__item--bottom-center .video__label {
	top: auto;
	bottom: 0;
}

.l-showcase__item--bl .video__label.video__label--embed,
.l-showcase__item--br .video__label.video__label--embed,
.l-showcase__item--bottom-center .video__label.video__label--embed {
	margin-bottom: 0 !important;
	padding-bottom: 0 !important;
	top: auto;
	bottom: calc(var(--video-controls-height) + var(--video-controls-space) + 1rem);
}

@media (--mq-v2-from-large) {
	.l-showcase__item--bl .video__label.video__label--embed,
	.l-showcase__item--br .video__label.video__label--embed,
	.l-showcase__item--bottom-center .video__label.video__label--embed {
		bottom: calc(var(--video-controls-height-from-medium) + var(--video-controls-space-from-medium) + 1rem);
	}
}

@media (--mq-v2-from-xlarge) {
	.l-showcase__item--bl .video__label.video__label--embed,
	.l-showcase__item--br .video__label.video__label--embed,
	.l-showcase__item--bottom-center .video__label.video__label--embed {
		bottom: calc(var(--video-controls-height-from-wide) + var(--video-controls-space-from-wide) + 1rem);
	}
}

.video__container {
	display: block;
	overflow: hidden;
	padding-top: 50%;
	position: relative;
	width: 100%;
}

.video__container[data-aspect-ratio="1:1"] {
	padding-top: 100%;
}

.video__container[data-aspect-ratio="2:3"] {
	padding-top: 150%;
}

.video__container[data-aspect-ratio="4:3"] {
	padding-top: 75%;
}

.video__container[data-aspect-ratio="16:9"] {
	padding-top: 56.25%;
}

@media (--mq-v2-up-to-medium) {
	.l-showcase__item .video:not(.video--fill) .video__container {
		padding-top: 100% !important; /* showcase items are always square up to small. (Edit: we've started breaking this rule 06/08/2021) */
	}
}

.video--fill .video__container {
	padding-top: 0;
	height: 100%;
}

/*
	No need for object fit, the admin will upload cropped assets.
	If something looks broken:
	1. Check if the video asset is the correct aspect ratio
	2. Check if the correct aspect ratio is set on the HTML element
	3. Check if the .l-showcase__item didn't break it instead
*/
.video__element {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@supports (object-fit: cover) {
	.video--embed .video__element,
	.video--fill .video__element,
	.video--inline .video__element {
		object-fit: cover;
	}
}

.video__poster {
	display: block;
	width: 100%;
}

.video__poster.is-hidden {
	opacity: 0;
	pointer-events: none;
	transition: opacity 160ms linear;
}

.video__swap-label.is-loaded {
	opacity: 0;
	transition: opacity 160ms linear;
}

.video__swap-label.is-hidden {
	display: none;
}

.video__swap-label-text::after {
	content: attr(label-initial);
}

.video__swap-label.is-loading .video__swap-label-text::after {
	content: attr(label-loading);
}

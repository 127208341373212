/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
*::selection {
	background-color: #fff799;
	color: #000000;
}

.theme-yellow *::selection {
	background-color: #000000;
	color: #ffffff;
}

@media (prefers-reduced-motion: no-preference) {
	html {
		scroll-behavior: smooth;
	}
}

body {
	background-color: var(--color-background);
	box-sizing: border-box;
	color: var(--color-foreground);
	position: relative;
	min-height: 100vh;
}

body.is-showing-overlay {
	overflow: hidden;
}

textarea {
	resize: none;
}

hr {
	background-color: currentColor;
	border: none;
	height: 1px;
}

img,
video {
	display: block;
	max-width: 100%;
}

table,
th,
td {
	border: 1px solid currentColor;
}

table {
	border-collapse: collapse;
	box-sizing: border-box;
	margin: 1rem 0 2rem 0;
	width: 100%;
	max-width: 100%;
}

*:first-child > table {
	margin-top: 0;
}

*:last-child > table {
	margin-bottom: 0;
}

.mr-table-scroll-container figure.wp-block-table {
	margin: 1rem 0 2rem 0;
}

th,
td {
	box-sizing: border-box;
	padding: var(--space-8) var(--space-8--px);
	text-align: left;
}

@media (--mq-v2-from-large) {
	th,
	td {
		padding: var(--space-16) var(--space-16--px);
	}
}

ol:not([class]),
ul:not([class]) {
	padding-left: 1rem;
}

p > img {
	display: block;
	width: 100%;
	max-width: 100%;
}

a:not([class]),
a.a-default {
	color: currentColor;
}

:is(p > a:not([class]), html a.a-default) {
	border-bottom: 1px solid var(--color-alpha-foreground-55);
	display: inline;
	line-height: 1.33;
	text-decoration: none;
	transition: border-bottom-color 96ms linear;
}

:is(p > a:not([class]), html a.a-default):is(:active, :focus) {
	background-color: var(--color-yellow);
	color: var(--color-black);
	outline: 0;
}

:is(p > a:not([class]), html a.a-default):is(:hover, :focus) {
	border-bottom-color: var(--color-foreground);
}

.theme-black p > a:not([class]),
html .theme-black a.a-default {
	border-bottom-color: var(--color-alpha-white-55);
}

.theme-black p > a:not([class]):is(:hover, :focus),
html .theme-black a.a-default:is(:hover, :focus) {
	border-bottom-color: var(--color-white);
}

.theme-yellow p > a:not([class]):is(:active, :focus),
html .theme-yellow a.a-default:is(:active, :focus) {
	background-color: var(--color-black);
	color: var(--color-white);
}

/**
 * Do not use this on a grid layout.
 * The pseudo element would become an empty grid item.
 */
*:target::before {
	content: "";
	display: block;
	margin-top: calc(var(--site-header-height) * -1);
	pointer-events: none;
	visibility: hidden;
	height: var(--site-header-height);
}

@media (--mq-v2-from-large) {
	*:target::before {
		margin-top: calc(var(--site-header-height-from-medium) * -1);
		height: var(--site-header-height-from-medium);
	}
}

body[has-notice-bar] *:target::before {
	margin-top: calc(var(--site-header-with-notice-bar-height) * -1);
	height: var(--site-header-with-notice-bar-height);
}

@media (--mq-v2-from-large) {
	body[has-notice-bar] *:target::before {
		margin-top: calc(var(--site-header-with-notice-bar-height-from-medium) * -1);
		height: var(--site-header-with-notice-bar-height-from-medium);
	}
}
